import styled from "styled-components";
import BaseAlert from "./BaseAlert";
import arrowUp from "./assets/arrow-circle-up.svg";
import events from "../../../packages/eventBus";

const ForceProAlert = () => {
  const handleClick = () => {
    events.openSubscriptionSettings.emit();
  };

  return (
    <AlertWrapper onClick={handleClick}>
      <Image src={arrowUp} />
      <TextItem>ⓘ</TextItem>
      <TextItem>
        Upgrade your license
        <br />
        to PRO
      </TextItem>
    </AlertWrapper>
  );
};

const AlertWrapper = styled(BaseAlert)`
  background: linear-gradient(103.73deg, #9b45e4 1.13%, #e13a9d 117.81%);
  margin: 0 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 25px 0;
  cursor: pointer;
`;
const TextItem = styled.div`
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  z-index: 0;
  ${AlertWrapper}:hover & {
    opacity: 0.8;
  }
`;
const Image = styled.img`
  position: absolute;
  right: 0;
  filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.2));
  z-index: 0;
`;

export default ForceProAlert;
