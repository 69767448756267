import { fetchSubscriptionFailure, fetchSubscriptions, fetchSubscriptionsSuccess } from "./actions";
import { fetchSubscriptions as fetchSubscriptionsApi } from "../api";

export const fetchSubscriptionsThunk = () => async (dispatch) => {
  dispatch(fetchSubscriptions());

  try {
    const response = await fetchSubscriptionsApi();

    dispatch(fetchSubscriptionsSuccess(response.data));
  } catch (e) {
    console.error(e);
    dispatch(fetchSubscriptionFailure());
  }
};
