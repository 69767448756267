import {
  deletePreset,
  getFiltersPresets,
  setFiltersAsset,
  setPresetError,
} from ".";
import { nanoid } from "nanoid";
import { getActiveSignalsType } from "../client";
import { setActiveSignalsType } from "../client/actions";

export const createPresetThunk = (preset) => (dispatch, getState) => {
  const state = getState();
  const presets = getFiltersPresets(state);

  const isset = presets.find((pr) => pr.presetName === preset.presetName);

  if (isset) {
    dispatch(setPresetError(true));
    setTimeout(() => {
      dispatch(setPresetError(false));
    }, 5000);

    return;
  }

  dispatch(
    setFiltersAsset({
      ...preset,
      presetId: nanoid(8),
    })
  );
};

export const deletePresetThunk = (presetId) => (dispatch, getState) => {
  const state = getState();
  const activeSignalsType = getActiveSignalsType(state);

  if (activeSignalsType.id === presetId) {
    dispatch(setActiveSignalsType({ id: "10", term: null }));
  }

  dispatch(deletePreset(presetId));
};
