import { createStore, compose, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import rootReducer from "./modules";
import params from "./config";

const buildMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(ReduxThunk);
  } else {
    return compose(
      applyMiddleware(ReduxThunk),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (noop: any) => noop
    );
  }
};

// TODO: enable encryption!
const encryptStore = encryptTransform({
  secretKey: params.encryptStoreKey,
  onError: function (error) {
    localStorage.clear();
  },
});

const createAppStore = () => {
  const persistedReducer = persistReducer(
    {
      key: "state",
      storage,
      // transforms: [ encryptStore ],
    },
    rootReducer
  );

  return createStore(persistedReducer, buildMiddleware());
};

const store = createAppStore();

export type State = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type GetState = () => State;

export default store;
