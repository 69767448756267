import eventBus from "../eventBus";

const eventName = "openSubscriptionSettings";

const subscribe = (callback) => {
  return eventBus.subscribe(eventName, callback);
};

const emit = () => {
  return eventBus.emit(eventName);
};

export default {
  subscribe,
  emit,
};
