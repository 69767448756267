import React, { Component } from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { ReflexContainer, ReflexSplitter, ReflexElement } from "react-reflex";
import PropTypes from "prop-types";

import { getLayoutSettings, getDashboardScale, setDashboardScale } from "../../modules/client";
import LeftPanel from "../LeftPanel";
import WebTabsPanel from "../WebTabsPanel";
import DashboardPanel from "../DashboardPanel";
import Menu from "../Menu";
import PopupWindows from "../PopupWindows";
import { isAuth } from "../../modules/mainSettings";
import packageJSON from "../../../package.json";

import { ReactComponent as MinimizeIcon } from "./assets/minimize.svg";
import { ReactComponent as FullScreenIcon } from "./assets/fullscreen.svg";
import { ReactComponent as CloseIcon } from "./assets/close.svg";

import events from "../../packages/eventBus";

import "./index.css";
import "react-reflex/styles.css";

const defaultLayout = (isAuth) => ({
  showTypeSettings: false,
  showBrokerSettings: false,
  showAppSettings: !isAuth,
  showVersionInfo: false,
  showTutorial: false,
  showTelegramMessages: false,
});

class Layout extends Component {
  static propTypes = {
    leftPanel: PropTypes.bool.isRequired,
    dashboardPanel: PropTypes.bool.isRequired,
  };

  state = {
    ...defaultLayout(this.props.isAuth),
    isResizing: false,
  };

  transformWrapper = React.createRef();

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    events.toggleVisibilityTabContent.emit(nextState.showAppSettings);

    return true;
  }

  componentDidMount() {
    window.addEventListener("resize", (e) => {
      this.handleResize();
    });

    events.openSubscriptionSettings.subscribe(() => {
      this.setState({
        showTypeSettings: false,
        showBrokerSettings: false,
        showAppSettings: true,
        showVersionInfo: false,
      });
    });
  }

  handleResize = () => {
    if (!this.transformWrapper.current) return;
    const { height } = this.transformWrapper.current.getBoundingClientRect();
    const scale = height / 207;
    if (this.props.scale !== scale) this.props.setDashboardScale(scale);
  };

  menuButton = (stateIndex) => {
    const value = this.state[stateIndex];
    return {
      isActive: value,
      handleClick: ((stateIndex) => () => {
        const value = this.state[stateIndex];
        this.setState({
          ...defaultLayout(this.props.isAuth),
          [stateIndex]: !value,
        });
      })(stateIndex),
    };
  };

  handleMinimize = () => {
    window.electron?.minimize();
  };

  handleFullscreen = () => {
    window.electron?.fullscreen();
  };

  handleClose = () => {
    window.electron?.close();
  };

  render() {
    const { showBrokerSettings, isResizing, showTelegramMessages } = this.state;
    const { leftPanel, dashboardPanel, scale } = this.props;
    const panelSize = 207 * (scale || 1);

    return (
      <>
        <TitleBarSection>
          <TitleBarElement />
          <TitleBarElement>
            <TitleBarTitle>
              {packageJSON.name} v{packageJSON.version}
            </TitleBarTitle>
          </TitleBarElement>
          {window.electron?.platform === "macOS>" ? (
            <TitleBarButtons>
              <MinimizeButton onClick={this.handleMinimize} />
              <FullScreenButton onClick={this.handleFullscreen} />
              <CloseButton onClick={this.handleClose} />
            </TitleBarButtons>
          ) : (
            <TitleBarElement />
          )}
        </TitleBarSection>
        <section id="main">
          <Menu
            brokerSettings={this.menuButton("showBrokerSettings")}
            typeSettings={this.menuButton("showTypeSettings")}
            appSettings={this.menuButton("showAppSettings")}
            versionInfo={this.menuButton("showVersionInfo")}
            tutorial={this.menuButton("showTutorial")}
            telegramMessages={this.menuButton("showTelegramMessages")}
          />
          <LeftPanel
            {...{
              showSignalsList: leftPanel,
              showBrokerSettings,
              showTelegramMessages,
            }}
            typeSettings={this.menuButton("showTypeSettings")}
          />
          <div className="graph_wrap">
            <ReflexContainer orientation={"horizontal"}>
              <ReflexElement>
                <div style={{ width: "100%", height: "100%" }}>
                  <WebTabsPanel {...{ isResizing }} />
                </div>
              </ReflexElement>

              <ReflexSplitter
                onStartResize={() => {
                  this.handleResize();
                }}
                onStopResize={() => {
                  this.handleResize();
                }}
              />

              <ReflexElement maxSize={300} minSize={160} size={panelSize}>
                {dashboardPanel && (
                  <DashboardPanel
                    transformWrapper={this.transformWrapper}
                    scale={scale}
                    height={panelSize}
                    handleResize={this.handleResize}
                  />
                )}
              </ReflexElement>
            </ReflexContainer>
          </div>
        </section>
        <PopupWindows
          appSettings={this.menuButton("showAppSettings")}
          versionInfo={this.menuButton("showVersionInfo")}
          tutorial={this.menuButton("showTutorial")}
        />
      </>
    );
  }
}

const TitleBarSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  //padding-left: 70px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  height: 27px;
  //height: ${window.electron?.platform === "Windows" ? "32px" : "27px"};
  width: 100%;
  background-color: #3a4255;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  -webkit-app-region: drag;
`;

const TitleBarElement = styled.div`
  display: flex;
  flex: 0 0 33%;
  justify-content: center;
`;

const TitleBarButtons = styled(TitleBarElement)`
  display: flex;
  justify-content: flex-end;
  -webkit-app-region: no-drag;
  user-select: all;
  &:hover {
    cursor: pointer;
  }
`;

const TitleBarTitle = styled.div`
  opacity: 0.35;
  font-weight: 500;
  font-size: 12px;
`;

const TitleBarButton = () => css`
  svg {
  }
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const MinimizeButton = styled(MinimizeIcon)`
  ${() => TitleBarButton()};
`;

const FullScreenButton = styled(FullScreenIcon)`
  ${() => TitleBarButton()};
`;

const CloseButton = styled(CloseIcon)`
  ${() => TitleBarButton()};
`;

export default connect(
  (state) => ({
    ...getLayoutSettings(state),
    isAuth: isAuth(state),
    scale: getDashboardScale(state),
  }),
  { setDashboardScale }
)(Layout);
