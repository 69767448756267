import React from "react";
import styled, { keyframes } from "styled-components";

const Loader = () => {
  return (
    <Wrapper>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  );
};

const anim1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const anim2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(14px, 0);
  }
`;

const anim3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 34px;
  height: 15px;
  & div {
    position: absolute;
    top: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  & div:nth-child(1) {
    left: 0;
    animation: ${anim1} 0.6s infinite;
  }
  & div:nth-child(2) {
    left: 0;
    animation: ${anim2} 0.6s infinite;
  }
  & div:nth-child(3) {
    left: 14px;
    animation: ${anim2} 0.6s infinite;
  }
  & div:nth-child(4) {
    left: 28px;
    animation: ${anim3} 0.6s infinite;
  }
`;

export default Loader;
