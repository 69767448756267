import { createAction } from "redux-actions";

export const setCustomBrokersList = createAction("MAIN_SETTINGS/SET_CUSTOM_BROKERS_LIST");

// payload { id: 1, title: "", url: "https://..." }
export const addCustomBrokersItem = createAction("MAIN_SETTINGS/ADD_CUSTOM_BROKERS_ITEM");

export const removeCustomBrokersItem = createAction("MAIN_SETTINGS/REMOVE_CUSTOM_BROKERS_ITEM");

export const setLicenseKey = createAction("MAIN_SETTINGS/SET_LICENSE_KEY");
export const removeLicenseKey = createAction("MAIN_SETTINGS/REMOVE_LICENSE_KEY");

export const setClientPRO = createAction("MAIN_SETTINGS/SET_CLIENT_PRO");

export const setClientFree = createAction("MAIN_SETTINGS/SET_CLIENT_FREE");

export const setCredentials = createAction("MAIN_SETTINGS/SET_CLIENT_FREE");

export const setLicenseKeyError = createAction("MAIN_SETTINGS/SET_LICENSE_KEY_ERROR");
