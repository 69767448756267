import Select from "react-select";

const selectStyles = ({ disabled }) => ({
  placeholder: provided => ({
    ...provided,
    fontSize: 13,
    fontWeight: 500,
  }),
  input: provided => ({
    ...provided,
    fontSize: 13,
    fontWeight: 500,
    color: "#FFF",
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: 13,
    fontWeight: 500,
    color: disabled ? "#777" : "#FFF",
  }),
  control: provided => ({
    ...provided,
    color: "#FFF",
    borderColor: "#2d364e",
    fontFamily: "Montserrat",
  }),
  menu: provided => ({
    ...provided,
    fontFamily: "Montserrat",
    color: "#FFF",
    fontSize: 13,
    fontWeight: 500,
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: 140
  })
});

const CustomSelect = (props) => (
  <Select
    {...props}
    styles={selectStyles({ disabled: props.isDisabled })}
    theme={theme => ({
      ...theme,
      borderRadius: 5,
      colors: {
        ...theme.colors,
        neutral0: props.background ? props.background : "#2d364e",
        neutral5: props.background ? props.background : "#2d364e",
        primary25: "#2275D7",
      },
    })}

  />
);


export default CustomSelect;