import React from "react";

const View = ({ value, isNull }) => (
  <div className="graph_battery">
    {/* <!-- График в виде батареи --> */}
    {/* <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс graph_battery_img1-graph_battery_img12 становится видна --> */}
    <div className="graph_battery_img">
      {!isNull && (
        <div
          className="graph_battery_img_value"
          style={{ height: `${value}px` }}
        ></div>
      )}
    </div>
    {/* <!-- График в виде батареи конец --> */}
  </div>
);

export default View;