import styled, { css } from "styled-components";

const RadioSelector = ({ LabelComponent, children, id, ...rest }) => (
  <StyledRadioContainer>
    <StyledRadioInput
      type="radio"
      id={id}
      {...rest}
      LabelComponent={LabelComponent}
    />
    <StyledRadioLabel htmlFor={id}>
      <StyledRadioLabelContainer>
        {children}
      </StyledRadioLabelContainer>
    </StyledRadioLabel>
  </StyledRadioContainer>
);

export default RadioSelector;

const StyledRadioContainer = styled.div`
  position: relative;
`;
const StyledRadioLabel = styled.label``;
const StyledRadioLabelContainer = styled.div`
  width: 222px;
  height: 117px;
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 35px;
  position: relative;
`;
const StyledRadioInput = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;

  & + ${StyledRadioLabel} {
    cursor: pointer;
  }

  & + ${StyledRadioLabel}:before {
    content: '';
    position: absolute;
    top: 120px;
    left: 50%;
    margin-left: -19px;
    width: 39px;
    height: 23px;
    transition: .2s;
    background: rgba(143, 155, 179, 0.16);
    border: 1px solid rgba(143, 155, 179, 0.7);
    box-sizing: border-box;
    border-radius: 100px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  & + ${StyledRadioLabel}:after {
    content: '';
    position: absolute;
    left: 94px;
    top: 122px;
    width: 19px;
    height: 19px;
    border-radius: 10px;
    background: #FFF;
    transition: .2s;
    opacity: 0.6;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  &:checked + ${StyledRadioLabel}:before {
    background: #407BFF;
    border: 1px solid #407BFF;
  }

  &:checked + ${StyledRadioLabel}:after {
    left: 110px;
    opacity: 1;
  }

  ${({LabelComponent}) => css`
    &:checked + ${StyledRadioLabel} ${StyledRadioLabelContainer} ${LabelComponent} {
      opacity: 1;
    }
  `}
`;