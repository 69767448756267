import styled, { css } from "styled-components";

const colors = {
  "1": "rgba(27,197,60,1)",
  "-1": "rgba(214,71,48,1)",
  // "-1": "#D64730",
};

const View = ({ signal, price, take_profit, stop_loss }) => (
  <Container>
    <OrdersWrapper>
      <Order color={colors[signal * -1]}>
        {Math.abs(signal === 1 ? stop_loss : take_profit)}
      </Order>
      <OrderDivider>/</OrderDivider>
      <Order color={colors[signal * 1]}>
        {Math.abs(signal === 1 ? take_profit : stop_loss)}
      </Order>
    </OrdersWrapper>
    <SignalWrapper color={{ "1": "#9FE6A0", "-1": "#FF665A" }[signal * 1]}>
      <SignalLabel>
        {signal === 1 ? "BUY" : "SELL"}
      </SignalLabel>
      <SignalPrice>
        {price}
      </SignalPrice>
    </SignalWrapper>
  </Container>
);

export default View;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 11px 0 10px 0;
`;
const OrdersWrapper = styled.div`
  display: flex;
`;
const Order = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-right: 5px;
  ${props => css`color: ${props.color}`}
`;
const OrderDivider = styled.div`
  font-size: 22px;
  font-weight: lighter;
  margin-right: 5px;
  color: #C1C1C1;
`;
const SignalWrapper = styled.div`
  opacity: 0.9;
  font-weight: bold;
  ${props => css`color: ${props.color}`}
`;
const SignalLabel = styled.div`
  font-size: 18px;
`;
const SignalPrice = styled.div`
  font-size: 12px;
`;