import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { receiveSignals, receiveInitialSignals } from "./actions";

export default combineReducers({
  list: handleActions(
    {
      [receiveSignals]: (state, action) => {
        const signals = action.payload;
        if (signals) return signals.reverse();
        else return state;
      },
      [receiveInitialSignals]: (state, action) => {
        const signals = action.payload;
        if (signals) return signals.reverse();
        else return state;
      },
    },
    [],
  ),
});
