import React, { Component } from "react";
import styled from "styled-components";
import isElectron from "is-electron";
import hotkeys from "hotkeys-js";
import events from "../../packages/eventBus";

import { brokerScripts } from "./brokerScripts";

class TabContent extends Component {
  state = { isInitialized: false, addedListeners: false, domReady: false, hidden: false };

  iframe = React.createRef();

  unsubOpenBrokerChart;
  unsubOpenBrokerPosition;
  unsubHideTabContent;

  componentDidMount() {
    this.check();

    this.unsubHideTabContent = events.toggleVisibilityTabContent.subscribe((status) => {
      this.setState((prev) => ({ ...prev, hidden: status }));
    });

    // FOR NATIVE C#
    this.unsubOpenBrokerChart = events.openBrokerChart.subscribe((options) => {
      const { broker, expiration, symbol } = options;
      const { brokerCode } = this.props;

      if (!symbol || !expiration || broker !== brokerCode) return;

      if (window.vfx_sent_to_native) {
        window.vfx_sent_to_native(
          JSON.stringify({
            event: "inject_script",
            id: broker,
            script: `changeSymbol('${broker}', '${symbol}', '${expiration}')`,
          })
        );
      }
    });

    // FOR NATIVE C#
    this.unsubOpenBrokerPosition = events.openBrokerPosition.subscribe((options) => {
      const { symbol, expiration, side, broker } = options;
      const { brokerCode } = this.props;

      if (side !== 1 && side !== -1) return;
      if (!symbol || !expiration || broker !== brokerCode) return;

      if (window.vfx_sent_to_native) {
        window.vfx_sent_to_native(
          JSON.stringify({
            event: "inject_script",
            id: broker,
            script: `openPosition('${broker}', '${symbol}', '${expiration}', ${side})`,
          })
        );
      }
    });
  }

  componentWillUnmount() {
    this.unsubOpenBrokerChart();
    this.unsubOpenBrokerPosition();
    this.unsubHideTabContent();
  }

  async componentDidUpdate() {
    this.check();
  }

  injectChangeSymbolScript = (options) => {
    if (!this.webview || !this.state.domReady) return;
    const { broker, expiration, symbol } = options;
    const { brokerCode } = this.props;

    if (!symbol || !expiration || broker !== brokerCode) return;

    this.webview.executeJavaScript(`changeSymbol('${broker}', '${symbol}', '${expiration}')`);
  };

  injectOpenPositionScript = (options) => {
    if (!this.webview || !this.state.domReady) return;
    const { symbol, expiration, side, broker } = options;
    const { brokerCode } = this.props;

    if (side !== 1 && side !== -1) return;
    if (!symbol || !expiration || broker !== brokerCode) return;

    this.webview.executeJavaScript(`openPosition('${broker}', '${symbol}', '${expiration}', ${side})`);
  };

  check = () => {
    const { tabId, setTabTitle, hasTitle } = this.props;
    const { isInitialized } = this.state;

    if (typeof setTabTitle === "function" && !isInitialized) {
      this.setState({ isInitialized: true }, () => {
        if (isElectron() && this.webview) {
          this.webview.addEventListener("page-title-updated", () => {
            setTabTitle({ id: tabId, title: this.webview.getTitle() });
          });
        } else {
          if (hasTitle) return;
          setTabTitle({ id: tabId, title: `Tab ${tabId}` });

          // const { current: iframe } = this.iframe;
          // iframe.addEventListener("load", (e) => {
          // });
        }
      });
    }
  };

  renderWeb() {
    const { url, isResizing, isActive, brokerCode, tabId } = this.props;
    let style = { height: "100%", width: "100%" };
    if (!isActive || isResizing) style = { ...style, display: "none" };

    if (this.state.hidden) {
      style = { ...style, display: "none" };
    }

    return <iframe id={brokerCode || tabId} ref={this.iframe} src-data={url} src={""} title={url} style={style} />;
  }

  renderElectron() {
    const { url, isResizing, isActive, userAgent } = this.props;
    const { addedListeners } = this.state;

    let style = { height: "100%" };
    // if (!isActive || isResizing) style = { ...style, display: "none" };
    if (!isActive) style = { ...style, display: "none" };
    return (
      <Container style={style}>
        <webview
          ref={(webview) => {
            if (!!webview && !addedListeners) {
              webview.addEventListener("new-window", (e) => {
                if (e.url.indexOf("vfxaction=newwnd") > -1) window.shell.openExternal(e.url);
                else webview.src = e.url;
              });
              webview.addEventListener("dom-ready", () => {
                this.webview = webview;

                webview.executeJavaScript(brokerScripts);

                webview.insertCSS("::-webkit-scrollbar { width: 0; }");
                hotkeys("ctrl+shift+j+k", () => {
                  try {
                    this.webview.openDevTools();
                  } catch (e) {
                    console.error(e);
                  }
                });
                this.setState((prev) => ({
                  ...prev,
                  domReady: true,
                }));
              });

              this.setState({ addedListeners: true });
            }
          }}
          src={url}
          autosize="on"
          style={style}
          useragent={userAgent}
        />
      </Container>
    );
  }

  render() {
    return isElectron() ? this.renderElectron() : this.renderWeb();
  }
}

const Container = styled.div`
  position: relative;
  background-color: #1e2332;
`;

export default TabContent;
