import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  TermSwitcher,
  Summary,
  PivotPoints,
  Volatility,
  // Zigzag,
} from "./items";
import View from "./View";
import { buildDashboardValues } from "./util";

class Dashboard extends Component {
  static propTypes = {
    onTermChange: PropTypes.func.isRequired,
    enabled: PropTypes.bool.isRequired,
    term: PropTypes.number.isRequired,
    symbol: PropTypes.string.isRequired,
    visible: PropTypes.shape({
      summary: PropTypes.bool.isRequired,
      zigzag: PropTypes.bool.isRequired,
      piv: PropTypes.bool.isRequired,
      volatility: PropTypes.bool.isRequired,
    }).isRequired,
    values: PropTypes.shape({
      symbol: PropTypes.string.isRequired, // EURUSD,
      period: PropTypes.string.isRequired, // 1,
      pp: PropTypes.string.isRequired, // 5.4,
      rsi: PropTypes.string.isRequired, // 50.23,
      cci: PropTypes.string.isRequired, // 27.81,
      trends: PropTypes.string.isRequired, // 0,
      bulls: PropTypes.string.isRequired, // 0,
      bears: PropTypes.string.isRequired, // 0,
      vol: PropTypes.string.isRequired, // 8,
      zza: PropTypes.string.isRequired, // 7,
      zzb: PropTypes.string.isRequired, // 7,
      zzc: PropTypes.string.isRequired, // 0,
      zzd: PropTypes.string.isRequired, // 0,
      isummary: PropTypes.string.isRequired, // 5.0\r
    }),
  };

  render() {
    const { onTermChange, term, symbol, visible, enabled, values } = this.props;
    if (!enabled) return null;

    const { piv, volatility, summary, /*zigzag*/ } = buildDashboardValues(values);

    return (
      <View>
        <TermSwitcher value={term} onChange={onTermChange} />
        {/* {visible.zigzag && <Zigzag value={zigzag} isNull={!values} />} */}
        {visible.piv && <PivotPoints value={piv} isNull={!values} />}
        {visible.summary && <Summary symbol={symbol} value={summary} isNull={!values} />}
        {visible.volatility && <Volatility value={volatility} isNull={!values} />}
      </View>
    );
  }
}

export default Dashboard;
