export const getTabs = (state) =>
  state.client.tabs.sort(function (a, b) {
    return a.id - b.id;
  });
export const getActiveTabId = (state) => state.client.activeTabId;
export const getActiveBroker = (state) => state.client.activeBroker;
export const getActiveSignalsType = (state) => state.client.activeSignalsType;
export const getLayoutSettings = (state) => state.client.layout;
export const getDashboardScale = (state) => state.client.dashboardScale;

export const getCurrentTms = (state) => state.client.tms + state.client.tmsShift * 1000;

export const getSignalView = (state) => state.client.signalView;

export const getShowConnectBrokerPage = (state) => state.client.showConnectBrokerPage || false;

export const getAvailableBrokerIntegrationList = (state) => state.client.availableBrokerIntegrationList;
