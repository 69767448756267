import styled from "styled-components";
import errorLogo from "./assets/error-logo.svg";
import Button from "../base/form/Button";

const View = () => (
  <Container>
    <Logo src={errorLogo}/>
    <Text>
      Something went wrong<br/>
      Restore to default
    </Text>
    <TextBig>Click to restore</TextBig>
    <StyledButton
      onClick={(e) => {
        localStorage.clear();
        window.location.reload();
      }}
    >
      Restore
    </StyledButton>
  </Container>
);

const Container = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const Logo = styled.img`
  width: 370px;
  height: 250px;
`;
const Text = styled.p`
  color: #ffffff;
  text-align: center;
  font-size: 17px;
`;
const TextBig = styled(Text)`
  font-weight: 600;
  font-size: 25px;
`;
const StyledButton = styled(Button)`
  padding: 10px 30px;
  font-size: 17px;
`;

export default View;