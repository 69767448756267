import React, { Component } from "react";
import PropTypes from "prop-types";
import View from "./View";
import moment from "moment";

const shortAlgNames = {
  11: "MA",
  12: "RSI",
  13: "CCI",
  14: "PSAR",
  15: "AD",
};

class Signal extends Component {
  static propTypes = {
    currentTms: PropTypes.number.isRequired,
    variant: PropTypes.number.isRequired,
    algorithms: PropTypes.object.isRequired,
    showFull: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
      expiration: PropTypes.number.isRequired,
      signal: PropTypes.number.isRequired,
      alg: PropTypes.number.isRequired,
      price: PropTypes.string.isRequired,
      power: PropTypes.number.isRequired,
      hm: PropTypes.shape({
        m1: PropTypes.number.isRequired,
        m5: PropTypes.number.isRequired,
        m15: PropTypes.number.isRequired,
        m30: PropTypes.number.isRequired,
        h1: PropTypes.number.isRequired,
        h4: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    onOpenPosition: PropTypes.func.isRequired,
    onOpenChartsClicked: PropTypes.func.isRequired,
    onSetSymbolClicked: PropTypes.func.isRequired,
  };

  state = {
    isMouseHover: false,
  };

  handleMouseEnter = () => {
    this.setState({
      isMouseHover: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      isMouseHover: false,
    });
  };

  render() {
    const {
      data: { alg, power, timestamp },
      showFull,
      currentTms,
      variant,
      activeBroker,
      availableBrokerIntegrationList,
      //algorithms, // (из настроек с сервера кривые имена, поэтому хардкод shortAlgName)
    } = this.props;

    const updated = moment(moment().diff(timestamp * 1000)).format("mm:ss");
    // const updatedServerSync = moment(
    //   moment(currentTms).diff(timestamp * 1000)
    // ).format("mm:ss");
    const updatedServerSync = new Date(currentTms - timestamp * 1000).toISOString().substr(14, 5);

    const data = {
      ...this.props.data,
      updated: updated,
      alg: shortAlgNames[alg],
      power: power,
      showFull,
      variant,
      updatedServerSync,
      activeBroker,
      availableBrokerIntegrationList,
    };

    return (
      <View
        {...data}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        isMouseHover={this.state.isMouseHover}
        onOpenPosition={this.props.onOpenPosition}
        onOpenChartsClicked={this.props.onOpenChartsClicked}
        onSetSymbolClicked={this.props.onSetSymbolClicked}
      />
    );
  }
}

export default Signal;
