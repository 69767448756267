import React from "react";

const View = ({ imgIndex, arrowPosition, isNull }) => (
  <div className="graph_piv">
    {/* <!-- Стрелка для графика --> */}
    {/* <!-- В атрибуте style можно регулировать ее положение от 8рх-170рх --> */}
    {!isNull && (
      <div
        className="graph_piv_arrow"
        style={{ top: `${arrowPosition}px` }}
      />
    )}
    {/* <!-- Стрелка для графика конец --> */}
    {/* <!-- График PIV --> */}
    {/* <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс graph_piv_img1-graph_piv_img7 становится видна --> */}
    <div className={`graph_piv_img graph_piv_img${imgIndex}`}/>
    {/* <!-- График PIV конец --> */}
  </div>
);

export default View;