import { createAction, handleActions } from "redux-actions";
import params from "../config";

const { actualStorageVersion } = params;

const invalidateStorageAction = createAction("CLIENT/SET_DASHBOARD_SCALE");

export default handleActions({
  [invalidateStorageAction]: (state) => state,
}, actualStorageVersion);

export const invalidateStorage = invalidateStorageAction;

export const getStorageVersion = (state) => state.storageVersion;
