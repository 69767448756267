import React from "react";
import styled from "styled-components";
import Radio from "../../../../../base/form/Radio";

const Payment = ({ checked, id, label, onPaymentChange, image }) => {
  return (
    <Container>
      <Radio style={{ marginBottom: 15 }} active={checked} label={label} onClick={() => onPaymentChange(id)} />
      {image}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

export default Payment;
