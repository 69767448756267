import { ReactComponent as UpperPriceIcon } from "../assets/upper-price-icon.svg";
import { ReactComponent as LowerPriceIcon } from "../assets/lower-price-icon.svg";

import styled, { css } from "styled-components";

const colors = {
  "1": "rgba(27,197,60,0.6)",
  "-1": "rgba(214,71,48,0.6)",
  // "-1": "#D64730",
};
const orderItemSize = "12px";

const View = (props) => {
  const { signal, price, take_profit, stop_loss } = props;

  const symbol_point = props.symbol_point > 0 ? props.symbol_point : 0.0001
  let fixed = Math.abs(Math.log(symbol_point)/Math.log(10));

  return (
    <Container>
      <OrdersWrapper>
        <Order color={colors[signal * 1]}>
          <UpperPriceIcon width={orderItemSize} height={orderItemSize}/>
          <OrderPrice>
            {(parseFloat(price) + Math.abs(signal === 1 ? take_profit : stop_loss) * symbol_point).toFixed(fixed)}
          </OrderPrice>
        </Order>
        <Order color={colors[signal * -1]}>
          <LowerPriceIcon width={orderItemSize} height={orderItemSize}/>
          <OrderPrice>
            {(parseFloat(price) - Math.abs(signal === -1 ? take_profit : stop_loss) * symbol_point).toFixed(fixed)}
          </OrderPrice>
        </Order>
      </OrdersWrapper>
      <SignalWrapper
        color={{ "1": "#9FE6A0", "-1": "#FF665A" }[signal * 1]}>{signal === 1 ? "BUY" : "SELL"}</SignalWrapper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const OrdersWrapper = styled.div`
  width: 50%;
  height: 39px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin: 6px 0;
`;
const SignalWrapper = styled.div`
  opacity: 0.9;
  font-size: 22px;
  font-weight: bold;
  ${props => css`color: ${props.color}`}
`;
const Order = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 3px;
  padding: 3px 10px 2px 10px;
  opacity: 0.9;
  //background-o
  ${props => css`background-color: ${props.color}`}
`;
const OrderPrice = styled.div`
  margin-left: 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: ${orderItemSize};
`;

export default View;