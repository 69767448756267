import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import {
  newTab,
  closeTab,
  setActiveSignalsType,
  setActiveBroker,
  toggleLayoutSettings,
  setActiveTab,
  setTabTitle,
  updateTms,
  updateTmsShift,
  setDashboardScale,
  setSignalView,
  setBrokerSignalSettings,
  setShowConnectBrokerPage,
  resetBrokerSignalSettings,
  setBrokerIntegrationList,
  resetBrokerIntegrationList,
} from "./actions";

// tabItem = {
//   id: int,
//   url: str,
// }

const availableBrokerIntegrationList = ["pocketoption", "olymptrade", "iqoption"];
// const availableBrokerIntegrationList = ["iqoption"];

export default combineReducers({
  layout: handleActions(
    {
      [toggleLayoutSettings]: (state, action) => action.payload,
    },
    { settingsIndex: 1, leftPanel: true, dashboardPanel: true }
  ),
  dashboardScale: handleActions(
    {
      [setDashboardScale]: (state, action) => (action.payload ? action.payload : state),
    },
    1
  ),
  tabs: handleActions(
    {
      [newTab]: (state, action) => {
        // { url: str, title: str, brokerCode?: str }
        const { url, title } = action.payload;
        const maxId = state.reduce((acc, { id }) => {
          if (typeof id === "string") return acc;

          return acc > id ? acc : id;
        }, 0);
        return [...state, { id: maxId + 1, ...action.payload, url, title }];
      },
      [setTabTitle]: (state, action) => {
        const tab = state.find(({ id }) => id === action.payload.id);
        if (!tab) return state;

        return [...state.filter(({ id }) => id !== action.payload.id), { ...tab, title: action.payload.title }];
      },
      [closeTab]: (state, action) => state.filter(({ id }) => id !== action.payload),
    },
    []
  ),
  activeTabId: handleActions(
    {
      [setActiveTab]: (state, action) => action.payload,
    },
    "helloTab"
  ),
  activeSignalsType: handleActions(
    {
      [setActiveSignalsType]: (state, action) => action.payload,
    },
    { id: "10", term: null }
  ),
  activeBroker: handleActions(
    {
      [setActiveBroker]: (state, action) => action.payload,
    },
    null
  ),
  tms: handleActions(
    {
      [updateTms]: (state, action) => action.payload,
    },
    Date.now()
  ),
  tmsShift: handleActions(
    {
      [updateTms]: () => 0,
      [updateTmsShift]: (state, action) => state + 1,
    },
    0
  ),
  signalView: handleActions(
    {
      [setSignalView]: (state, action) => action.payload,
    },
    1
  ),
  availableBrokerIntegrationList: handleActions(
    {
      [setBrokerIntegrationList]: (state, action) => action.payload,
      [resetBrokerIntegrationList]: () => availableBrokerIntegrationList,
    },
    availableBrokerIntegrationList
  ),
  showConnectBrokerPage: handleActions(
    {
      [setShowConnectBrokerPage]: (state, action) => action.payload,
    },
    false
  ),
});
