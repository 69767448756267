import React, { Component } from "react";
import View from "./View";
import { transformValue } from '../../util'

const viewArrowMax = 150;
const viewArrowMin = 25;
// const viewArrowMax = 170;
// const viewArrowMin = 8;
const valueMax = 5;
const valueMin = -5;
const imgScaleMin = 1;
const imgScaleMax = 7;

export default class PivotPoints extends Component {
  render() {
    const { value, isNull } = this.props;

    let arrowPosition = transformValue(value, valueMin, valueMax, viewArrowMin, viewArrowMax)
    arrowPosition = Math.round(viewArrowMax - (arrowPosition - viewArrowMin))

    let imgIndex = transformValue(value, valueMin, valueMax, imgScaleMin, imgScaleMax)
    imgIndex = value > 0 ? Math.floor(imgIndex) : Math.ceil(imgIndex);
    
    return <View {...{ imgIndex, arrowPosition, isNull }} />;
  }
}
