export const brokerScripts = `
  async function changeSymbol(broker, symbol, expiration) {
    if (!brokers[broker]) {
      console.error('unknown broker', broker);
      return;
    }
  
    brokers[broker].changeSymbol(symbol, expiration);
  }
  
  async function openPosition(broker, symbol, expiration, side) {
    if (!brokers[broker]) {
      console.error('unknown broker', broker);
      return;
    }
  
    await brokers[broker].changeSymbol(symbol, expiration);
    await brokers[broker].openPosition(side);
  }
  
  const brokers = {
    pocketoption: {
      changeSymbol: async (symbol, expiration) => {
        return new Promise((res, rej) => {
          try {
            // try to find symbol
            let attemptCount = 15;
            const menu = document.querySelector('.pair-number-wrap');
  
            const interval = setInterval(() => {
              console.log('[' + symbol + ']' + ' attempts left: ' + attemptCount);
              if (attemptCount < 1) {
                clearInterval(interval);
                console.log('[' + symbol + ']' + 'Not found. Interval cleared.');
                console.log('SYMBOL_CHANGED_FAILURE');
                rej('SYMBOL_CHANGED_FAILURE');
                return;
              }
  
              if (menu) {
                menu.click();
                const currenciesNode = document.querySelector('.assets-block__nav-item--currency');
                if (currenciesNode) {
                  currenciesNode.click();
                  const links = document.querySelectorAll('.alist__link');
                  links.forEach(link => {
                    if (link.querySelector('.alist__label').textContent.replace('/', '').replace(' ', '') === symbol) {
                      const starBtn = link.querySelector('.fa-star-o');
  
                      if (starBtn) {
                        starBtn.click();
                      }
  
                      link.click();
                      clearInterval(interval);
                      console.log('[' + symbol + ']' + 'Successfully. Interval cleared.');
                      console.log('SYMBOL_CHANGED_SUCCESSFULLY');
                      res('SYMBOL_CHANGED_SUCCESSFULLY')
                    }
                  })
                }
              }
              attemptCount -= 1;
              if (document.querySelector('.assets-block')) {
                document.querySelector('.assets-block').style.display = 'none';
              }
            }, 500);
  
            // change expiration
            const expirationInput = document.querySelector('.block--expiration-inputs');
            const expirationInputModal = document.querySelector('.expiration-inputs-list-modal');
            if (expirationInput) {
              const expirationText = expirationInput.querySelector('.value__val').textContent;
  
              if (!expirationInputModal) {
                expirationInput.querySelector('.control__value').click();
              }
              changeExpiration(expiration)
              expirationInput.querySelector('.control__value').click();
  
              if (/\\d{2}:\\d{2}:\\d{2}/.test(expirationText)) {
  
              } else {
  
              }
            }
  
          } catch (e) {
            console.log('Error:' + JSON.stringify(e));
          }
  
          function changeExpiration(expiration) {
            let rwNodes = document.querySelectorAll('.rw');
  
            const hoursValue = parseInt(rwNodes[0].querySelector('.input-field-wrapper').querySelector('input').value);
            const minutesValue = parseInt(rwNodes[1].querySelector('.input-field-wrapper').querySelector('input').value);
            const secondsValue = parseInt(rwNodes[2].querySelector('.input-field-wrapper').querySelector('input').value);
  
            const hoursPlusBtn = rwNodes[0].querySelector('.btn-plus');
            const hoursMinusBtn = rwNodes[0].querySelector('.btn-minus');
            const minutesPlusBtn = rwNodes[1].querySelector('.btn-plus');
            const minutesMinusBtn = rwNodes[1].querySelector('.btn-minus');
            const secondsMinusBtn = rwNodes[2].querySelector('.btn-minus');
  
            function setValue(currentValue, newValue, plusBtnNode, minusBtnNode) {
              if (currentValue > newValue) {
                for (let i = currentValue; i > newValue; i--) {
                  minusBtnNode.click();
                }
              } else if (currentValue < newValue) {
                for (let i = currentValue; i < newValue; i++) {
                  plusBtnNode.click();
                }
              }
            }
  
            switch (expiration) {
              case 60:
                setValue(hoursValue, 1, hoursPlusBtn, hoursMinusBtn);
                setValue(minutesValue, 0, minutesPlusBtn, minutesMinusBtn);
                setValue(secondsValue, 0, secondsMinusBtn, secondsMinusBtn);
                break;
              case 15:
                setValue(hoursValue, 0, hoursPlusBtn, hoursMinusBtn);
                setValue(hoursValue > 0 && minutesValue < 1 ? 1 : minutesValue, 15, minutesPlusBtn, minutesMinusBtn);
                setValue(secondsValue, 0, secondsMinusBtn, secondsMinusBtn);
                break;
              case 5:
                setValue(hoursValue, 0, hoursPlusBtn, hoursMinusBtn);
                setValue(hoursValue > 0 && minutesValue < 1 ? 1 : minutesValue, 5, minutesPlusBtn, minutesMinusBtn);
                setValue(secondsValue, 0, secondsMinusBtn, secondsMinusBtn);
                break;
              case 1:
                setValue(hoursValue, 0, hoursPlusBtn, hoursMinusBtn);
                setValue(hoursValue > 0 && minutesValue < 1 ? 1 : minutesValue, 1, minutesPlusBtn, minutesMinusBtn);
                setValue(secondsValue, 0, secondsMinusBtn, secondsMinusBtn);
                break;
              default:
                setValue(hoursValue, 0, hoursPlusBtn, hoursMinusBtn);
                setValue(hoursValue > 0 && minutesValue < 1 ? 1 : minutesValue, 15, minutesPlusBtn, minutesMinusBtn);
                setValue(secondsValue, 0, secondsMinusBtn, secondsMinusBtn);
            }
          }
        })
      },
      openPosition: async (side) => {
        return new Promise((res, rej) => {
          if (side !== 1 && side !== -1) {
            throw new Error('Property side will be equal 1 or -1');
          }
  
          try {
            if (side === 1) {
              document.querySelector('.btn-call').click();
            } else {
              document.querySelector('.btn-put').click();
            }
            console.log('OPEN_POSITION_SUCCESSFULLY');
            res('OPEN_POSITION_SUCCESSFULLY')
          } catch (e) {
            console.log('OPEN_POSITION_FAILURE');
            rej('OPEN_POSITION_FAILURE')
          }
        })
      }
    },
    olymptrade: {
      changeSymbol: async (symbol, expiration) => {
        return new Promise((res, rej) => {
          function changeSymbol(symbol, attemptsCount) {
            if (!attemptsCount) {
              console.log('SYMBOL_CHANGED_FAILURE');
              return;
            };
        
            try {
              const menu = document.querySelector('.asset-button');
              const assetsPanel = document.querySelector('.panel-assets');
          
              if (!menu && !assetsPanel) {
                console.log('SYMBOL_CHANGED_FAILURE');
                rej('SYMBOL_CHANGED_FAILURE');
                return;
              }
          
              if (!assetsPanel) {
                menu.click();
                document.querySelector('.sidebar-platform-minor').style.maxWidth = '0px';
              }
          
              function findAssets() {
                return document.querySelectorAll('.asset-item');
              }
          
              let assets = findAssets();
          
              if (!assets.length) {
                if (!assetsPanel) {
                  menu.click();
                }
                setTimeout(() => {
                  changeSymbol(symbol, attemptsCount - 1);
                }, 500)
                
                return;
              }
        
              const assetsList = document.querySelector('.asset-menu__list-scroll');
              assetsList.scrollTo(0, assetsList.scrollHeight);
        
              assets.forEach((assetNode) => {
                setTimeout(() => {
                  try {
                    const titleBlockNode = assetNode.querySelector('.asset-item__title-block');
                    const titleNode = titleBlockNode?.querySelector('.asset-item__title');
                    if (!titleNode) {
                      console.log('no asset found');
                      return;
                    }
          
                    if (titleNode.textContent.replace('/', '') === symbol) {
                      assetNode.click();
                      menu.click();
                      console.log('SYMBOL_CHANGED_SUCCESSFULLY');
                      res('SYMBOL_CHANGED_SUCCESSFULLY');
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }, 1000)
              })
            } catch (e) {
              console.error(e);
              console.log('SYMBOL_CHANGED_FAILURE');
              rej('SYMBOL_CHANGED_FAILURE');
            }
          }
          
          function changeExpiration(expiration) {
            const durationInput = document.querySelector('.duration-input');
            const btnsWrapper = durationInput.nextElementSibling;
            const plsBtn = btnsWrapper.querySelector('[data-test="deal-form-input-controls-plus"]');
            const mnsBtn = btnsWrapper.querySelector('[data-test="deal-form-input-controls-minus"]');
          
            while(!mnsBtn.disabled) {
              mnsBtn.click();
            }
          
            for (let i = 1; i < expiration; i++) {
              plsBtn.click();
            }
          }
          
          changeSymbol(symbol, 15);
          changeExpiration(expiration);
        })
      },
      openPosition: async (side) => {
        return new Promise((res, rej) => {
          function openPosition() {
            if (side !== 1 && side !== -1) {
              throw new Error('Property side will be equal 1 or -1');
            }
          
            try {
              if (side === 1) {
                document.querySelector('.deal-button_up').click();
              } else {
                document.querySelector('.deal-button_down').click();
              }
              
              console.log('OPEN_POSITION_SUCCESSFULLY');
              res('OPEN_POSITION_SUCCESSFULLY');
            } catch (e) {
              console.error(e);
              console.log('OPEN_POSITION_FAILURE');
              rej('OPEN_POSITION_FAILURE');
            }
          }
          
          openPosition();
        })
      }
    }
  }
`;
