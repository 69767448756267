import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

const Tab = ({ id, url, title, isActive, onSelect, onClose }) => {
  const tabRef = useRef(null);

  const [roundBordersPosition, setRoundBordersPosition] = useState("none");
  const [isLastTab, setIsLastTab] = useState(false);
  const [isFirstTab, setIsFirstTab] = useState(false);

  useEffect(() => {
    if (!tabRef.current) return;

    const prevTab = tabRef.current.previousElementSibling;
    const nextTab = tabRef.current.nextElementSibling;

    setRoundBordersPosition("none");
    setIsLastTab(false);
    setIsFirstTab(false);

    if (prevTab && prevTab.dataset.active === "true") {
      setRoundBordersPosition("left");
    }

    if (!prevTab) {
      setIsFirstTab(true);
    }

    if (nextTab && nextTab.dataset.active === "true") {
      setRoundBordersPosition("right");
    }

    if (nextTab.dataset.empty) {
      setIsLastTab(true);
    }
  });

  return (
    <Wrapper
      ref={tabRef}
      data-active={isActive}
      isActive={isActive}
      borders={roundBordersPosition}
      isLastTab={isLastTab}
      isFirstTab={isFirstTab}
    >
      <Text isActive={isActive} onClick={() => onSelect(id)}>
        {title ? title.substring(0, 30) : ``}
      </Text>
      {!isActive && onClose !== null ? <div onClick={() => onClose(id)} className="graph_tab_close" /> : <EmptyCloseIcon />}
    </Wrapper>
  );
};

const EmptyCloseIcon = styled.div`
  height: 10px;
  width: 10px;
`;

const Wrapper = styled.div`
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 10px;
  background: ${({ isActive }) => (isActive ? "#3a4255" : "#2f3545")};
  border-right: ${({ isLastTab }) => (isLastTab ? "none" : "1px solid rgba(255, 255, 255, 0.08)")};
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  //min-width: 136px;
  transition: none;
  ${({ isActive, borders, isFirstTab }) => {
    if (isActive)
      return css`
        background: #3a4255;
        border-radius: 0;
        border: 1px solid rgba(255, 255, 255, 0);
      `;

    if (borders === "left") {
      return css`
        border-top-left-radius: 6px;
      `;
    }

    if (borders === "right") {
      return css`
        border-top-right-radius: 6px;
      `;
    }
  }}
`;

const Text = styled.p`
  font-weight: 500;
  font-size: 12px;
  margin: 0 10px;
  color: ${({ isActive }) => (isActive ? "#fff" : "rgba(255, 255, 255, 0.4)")};
`;

export default Tab;
