export const getBrokersList = (state) => state.initial.data["brokers_list_extended"];

export const getSignalTypesList = (state) => state.initial.data["signals-category"];

const getDeprecatedType = (id) => {
  // TODO: remove deprecated
  const availableTypesDeprecated = {
    10: { market: "forex", alg_type: 0, market_id: 0 },
    11: { market: "crypto", alg_type: 1, market_id: 1 },
    20: { market: "forex", alg_type: 1, market_id: 0 },
    21: { market: "crypto", alg_type: 0, market_id: 1 },
    30: { market: "forex", alg_type: 3, market_id: 0 },
    90: { market: "forex", alg_type: 0, market_id: 9 },
  };

  return availableTypesDeprecated[parseInt(id)];
};

export const getAvailableSignalType = (state, id) => {
  const availableTypes = state?.initial?.data?.["available-signals-category"];
  const item = availableTypes?.find(({ deprecated_key }) => `${id}` === `${deprecated_key}`);

  if (!item) return getDeprecatedType(id);

  return {
    market: ["forex", "crypto"][item.market_id],
    market_id: item.market_id,
    alg_type: item.alg_type_id,
  };
};

export const getFiltersConfig = (state) => state.initial.data["env_settings"];

export const isDebug = (state) => state.initial.data["env_settings"]["debug"];

export const getFreeAssets = (state) => state.initial.data["env_settings"]["free_account_assets"];

export const getPopup = (state) => state.initial.data.popwindow;

export const isInitialLoaded = (state) => state.initial.isLoaded;
export const isInitialLoading = (state) => state.initial.isLoading;

export const isPopupShow = (state) => state.initial.showPopup;

export const getLinks = (state) => state.initial.data.links;
export const getGoproLink = (state) => state?.initial?.data?.links?.gopro;

export const getAccountInfo = (state) => state.initial.data["account_info"];
export const getAccountInfoEmail = (state) => state?.initial?.data?.account_info?.email;
export const getAccountInfoUserId = (state) => state?.initial?.data?.account_info?.user_id;
export const getAccountInfoLkey = (state) => state?.initial?.data?.lkey;
export const getAccountInfoRegisteredAt = (state) => state?.initial?.data?.account_info?.registered_at;

export const getSiteAuthToken = (state) => state.initial?.data?.["site-auth-token"];

export const getEnvCurrentTime = (state) => state?.initial?.data?.env_settings?.current_time;

export const getAccountInfoLinks = (state) => {
  try {
    const { link_disclaimer: disclaimer, link_agreement: agreement, link_policy: policy } = state.initial.data["account_info"];

    return {
      disclaimer,
      agreement,
      policy,
    };
  } catch (e) {
    return null;
  }
};

export const getAlgorithms = (state) => {
  const { alg } = getFiltersConfig(state);
  return alg;
};

export const getUserAgent = (state) => {
  const presets = {
    macOS: state?.initial?.data?.env_settings?.user_agent_mac,
    Windows: state?.initial?.data?.env_settings?.user_agent_win,
    other: state?.initial?.data?.env_settings?.user_agent,
  };
  const userAgent = presets[window.platform];
  if (!userAgent) {
    return presets.other;
  }

  return userAgent;
};

export const getWShost = (state) => state.initial?.data?.["ws-config"]?.host;
export const getWSchannel = (state) => state.initial?.data?.["ws-config"]?.channel;
