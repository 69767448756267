import React from "react";

const View = ({
  pwrImgIndex,
  pwrDegree,
  symbol,
  bullsImgIndex,
  bearsImgIndex,
  trendImgIndex,
  cci,
  rsi,
  isNull,
}) => (
  <div className="graph_arrow">
    {/* <!-- График со стрелкой --> */}
    {/* <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс graph_arrow_img1-graph_arrow_img5 становится видна --> */}
    <div className={`graph_arrow_img graph_arrow_img${pwrImgIndex}`}>
      {/* <!-- Стрелка графика --> */}
      {/* <!-- Изначально является не видимой. При добавлении классов graph_arrow_img_icon1-graph_arrow_img_icon5, меняет позицию относительно полу круга --> */}
      {/* <!--<div className="graph_arrow_img_icon graph_arrow_img_icon1"></div>--> */}
      <div className="graph_arrow_circlebox">
        {!isNull && (
          <div
            className="graph_arrow_circle"
            style={{ transform: `rotate(${pwrDegree}deg)` }}
          ></div>
        )}
      </div>
      {/* <!-- Стрелка графика конец --> */}
    </div>
    {/* <!-- График со стрелкой конец --> */}

    {/* <!-- Название валюты --> */}
    <p className="graph_arrow_money">{symbol.toUpperCase()}</p>
    {/* <!-- Название валюты конец --> */}

    <h6 className="equalizer_title">Bulls & Bears</h6>
    <div className="equalizer">
      <div className="equalizer_left">
        {/* <!-- График эквалайзером --> */}
        {/* <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс equalizer_left_img1-equalizer_left_img19 становится видна --> */}
        <div
          className={`equalizer_left_img equalizer_left_img${bullsImgIndex}`}
        ></div>
        {/* <!-- График эквалайзером конец --> */}
      </div>
      <div className="equalizer_right">
        {/* <!-- График эквалайзером --> */}
        {/* <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс equalizer_right_img1-equalizer_right_img19 становится видна --> */}
        <div
          className={`equalizer_right_img equalizer_right_img${bearsImgIndex}`}
        ></div>
        {/* <!-- График эквалайзером конец --> */}
      </div>
    </div>
    <div className="equalizer_power">
      <p className="equalizer_power_name">high</p>
      <p className="equalizer_power_name">low</p>
      <p className="equalizer_power_name">high</p>
    </div>
    <div className="graph_of_values">
      <div className="rci">
        <div className="rci_title">RSI</div>
        <div className="rci_value">{rsi}</div>
      </div>
      <div className="rci rci_center">
        <div className="rci_title">CCI</div>
        <div className="rci_value">{cci}</div>
      </div>
      <div className="graph_trends">
        <div className="graph_trends_title">Trends</div>
        <div className="graph_trends_icons">
          {/* <!-- Стрелка по заголовком "Trends" --> */}
          {/* <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс red1-red3, yellow1-yellow3, green1-green3 становится видна --> */}
          {/* <div className="graph_trend_icon red3"></div>
          <div className="graph_trend_icon yellow1"></div>
          <div className="graph_trend_icon yellow1"></div>
          <div className="graph_trend_icon green2"></div> */}
          <div className="graph_trend_icon"></div>
          <div className={`graph_trend_icon ${trendImgIndex}`}></div>
          <div className="graph_trend_icon"></div>
          {/* <!-- Стрелка по заголовком "Trends" конец --> */}
        </div>
      </div>
    </div>
  </div>
);

export default View;