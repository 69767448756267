
import { receiveSignals as receiveSignalsAction } from "./actions";
import { isDebug } from '../initial'

export const receiveSignals = (payload) => async (dispath, getState) => {
  const debugMode = isDebug(getState());
  if (debugMode) { 
    // console.log('received_signals', payload);
  }
  dispath(receiveSignalsAction(payload));
};
