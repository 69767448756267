import styled from "styled-components";
import BaseAlert from "../BaseAlert";

const View = ({ minutesRemain, secondsRemain, handleClick }) => {
  return (
    <AlertWrapper>
      <DiscountSale>SALE</DiscountSale>
      <DiscountPerc>-25%</DiscountPerc>
      <Message>Save up to 25%</Message>
      <Timer>
        <TimerBox>00</TimerBox>
        <TimerDelimiter>:</TimerDelimiter>
        <TimerBox>{minutesRemain}</TimerBox>
        <TimerDelimiter>:</TimerDelimiter>
        <TimerBox>{secondsRemain}</TimerBox>
      </Timer>
      <Button onClick={handleClick}>Buy PRO</Button>
    </AlertWrapper>
  );
};

const Discount = styled.div`
  position: absolute;
  background: #FF1315;
  box-shadow: 0px -7px 10px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  font-weight: 700;
  font-size: 23px;
  width: 130px;
  height: 28px;
  right: -40px;
`;
const DiscountSale = styled(Discount)`
  transform: rotate(45deg);
  top: 8px;
`;
const DiscountPerc = styled(Discount)`
  transform: rotate(-45deg);
  bottom: 6px;
  padding-left: 10px;
`;
const AlertWrapper = styled(BaseAlert)`
  background-color: #FFFFFF;
  margin: 0 0 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px 0 10px 12px;
`;
const Message = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #FF1315;;
`;
const Timer = styled.div`
  display: flex;
  width: 100px;
  color: #FF1315;
  font-size: 13px;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
`;
const TimerBox = styled.div`
  border: 1px solid #FF1315;
  padding: 4px 0;
  border-radius: 3px;
  width: 25px;
  display: flex;
  justify-content: space-around;
`;
const TimerDelimiter = styled.div``;
const Button = styled.div`
  display: flex;
  align-items: center;
  width: 75px;
  height: 23px;
  background: #FF1315;
  font-size: 10px;
  color: #FFFFFF;
  justify-content: space-around;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.21);
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

export default View;