import React, { createRef } from "react";
import ReactDOM from "react-dom";

import Notification from "./Notification";

export { Notification };

class SystemNotificationManager {
  constructor(containerId) {
    if (!SystemNotificationManager._instance) {
      SystemNotificationManager._instance = this;
    }

    if (!containerId) throw new Error("containerId required");
    this.containerId = containerId;

    this.isInit = false;
    this.notificationRef = createRef();

    return SystemNotificationManager._instance;
  }

  success = (title, body) => {
    setTimeout(() => {
      if (!this.isInit) this._mount();
      this.notificationRef.current?.openNotification({ type: "success", title, body });
    }, 0);
  };

  info = (title, body) => {
    setTimeout(() => {
      if (!this.isInit) this._mount();
      this.notificationRef.current?.openNotification({ type: "info", title, body });
    }, 0);
  };

  warning = (title, body) => {
    setTimeout(() => {
      if (!this.isInit) this._mount();
      this.notificationRef.current?.openNotification({ type: "warning", title, body });
    }, 0);
  };

  error = (title, body) => {
    setTimeout(() => {
      if (!this.isInit) this._mount();
      this.notificationRef.current?.openNotification({ type: "error", title, body });
    }, 0);
  };

  _mount = () => {
    try {
      const container = document.getElementById(this.containerId);

      if (!container) {
        console.error(new Error("System notification container not found"));
        return;
      }

      ReactDOM.render(<Notification ref={this.notificationRef} />, container);
      this.isInit = true;
    } catch (e) {
      console.error(e);
    }
  };
}

export default new SystemNotificationManager("live-notification-container");
