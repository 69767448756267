import React from "react";
import styled from "styled-components";

import packageJson from "../../../../../package.json";
import params from "../../../../config";
import logoVfx from "./assets/logo-vfxalert.svg";
import logoXsignals from "./assets/logo-xsignals.svg";

const View = ({ isActive, openLink, disclaimer, agreement, policy, docs }) => {
  const links = [
    {
      url: disclaimer,
      title: "Disclaimer Warning",
    },
    {
      url: agreement,
      title: "Terms of use",
    },
    {
      url: policy,
      title: "Privacy policy",
    },
  ];

  return (
    <>
      <div className={`modal_content modal_content1 ${isActive ? "active" : ""}`}>
        <div style={{ width: "100%", paddingTop: 100 }}>
          <div className="version_title">{params.appName}</div>
          <div className="version_title">
            Version {packageJson.version} alpha Build {packageJson.hash} for {window.electron?.platform ?? "browser"}.
          </div>
          <div className="version_title">Copyright {new Date().getFullYear()} X-Systems Ltd. All rights reserved.</div>
          <Support>
            <div className={"version_box_item"} style={{ marginRight: 50 }} onClick={() => openLink(docs)}>
              How it works
            </div>
            <div className={"version_box_item"} onClick={() => openLink("defbrowser::mailto:support@vfxalert.com")}>
              support@vfxalert.com
            </div>
          </Support>
          <div className="version_box">
            {links.map(({ url, title }, key) => (
              <div
                key={key}
                className="version_box_item"
                onClick={() => {
                  openLink(url);
                }}
              >
                {title}
              </div>
            ))}
            <Logo src={params.appEnvType !== "classic" ? logoVfx : logoXsignals} />
          </div>
        </div>
      </div>
    </>
  );
};

export default View;

const Logo = styled.img`
  width: 62px;
  height: 62px;
  margin-left: 150px;
`;

const Support = styled.div`
  display: flex;
  transform: translateY(140px);
`;
