/* eslint-disable import/no-anonymous-default-export */
import React from "react";

const View = ({
  isActive,
  list,
  presets,
  onClick,
  activeItem: { id: activeId, term: activeTerm },
}) => {
  return (
    <>
      {/* <!-- Список тип сигнала --> */}
      {/* <!-- Изначально скрыт, появляется при добавлении класса active --> */}
      <div className={`signal_type ${isActive ? "active" : ""}`}>
        <div className="signal_type_title">Signals type</div>
        <div className="signal_type_wrap">
          {list &&
            list.length &&
            list.map(([id, name]) => (
              <div className="signal_type_item" key={id}>
                {/* <!-- Элемент Signal Type с галочкой --> */}
                {/* <!-- Изначально выглядит пассивным. При добавлении класса active становится активным. Галочка становится  --> */}
                <div
                  className={`signal_type_name_box ${
                    id.toString() === activeId.toString() ? "active" : ""
                  }`}
                >
                  <span></span>
                  <div className="signal_type_name">{name}</div>
                </div>
                {/* <!-- Элемент Signal Type с галочкой конец --> */}
                <div className="signal_type_graph">
                  {/* <!-- Цифровые значения Signal Type --> */}
                  {/* <!-- Изначально все элементы пассивные. При добавлении класса active становится активным. Цифра обводится в круглешок и становится белой  --> */}
                  {[1, 5, 15, 60].map((value) => (
                    <div
                      className={
                        id.toString() === activeId.toString() &&
                        activeTerm === value
                          ? "active"
                          : ""
                      }
                      key={value}
                      onClick={() => {
                        onClick({ id, term: value });
                      }}
                    >
                      {value}
                    </div>
                  ))}
                  <div
                    className={
                      id.toString() === activeId.toString() && !activeTerm
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      onClick({ id, term: null });
                    }}
                  >
                    All
                  </div>
                  {/* <!-- Цифровые значения Signal Type конец --> */}
                </div>
              </div>
            ))}
          {presets &&
            presets.length &&
            presets.map((preset) => (
              <div className="signal_type_item" key={preset.presetId}>
                {/* <!-- Элемент Signal Type с галочкой --> */}
                {/* <!-- Изначально выглядит пассивным. При добавлении класса active становится активным. Галочка становится  --> */}
                <div
                  className={`signal_type_name_box ${
                    preset.presetId.toString() === activeId.toString()
                      ? "active"
                      : ""
                  }`}
                >
                  <span></span>
                  <div className="signal_type_name">{preset.presetName}</div>
                </div>
                {/* <!-- Элемент Signal Type с галочкой конец --> */}
                <div className="signal_type_graph">
                  {/* <!-- Цифровые значения Signal Type --> */}
                  {/* <!-- Изначально все элементы пассивные. При добавлении класса active становится активным. Цифра обводится в круглешок и становится белой  --> */}
                  {preset.expiration
                    .sort((a, b) => a - b)
                    .map((value) => (
                      <div
                        className={
                          preset.presetId.toString() === activeId.toString() &&
                          activeTerm === value
                            ? "active"
                            : ""
                        }
                        key={value}
                        onClick={() => {
                          onClick({ id: preset.presetId, term: value });
                        }}
                      >
                        {value}
                      </div>
                    ))}
                  <div
                    className={
                      preset.presetId.toString() === activeId.toString() &&
                      !activeTerm
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      onClick({ id: preset.presetId, term: null });
                    }}
                  >
                    All
                  </div>
                  {/* <!-- Цифровые значения Signal Type конец --> */}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <!-- Список тип сигнала конец --> */}
    </>
  );
};

export default View;