import params from "../config";
import { YMInitializer } from 'react-yandex-metrika';

const Metrika = () => {
  if (process.env.NODE_ENV === "production") {
    return <YMInitializer accounts={[params.yandexMetrikaId]} />
  } else {
    return null;
  }
}

export default Metrika