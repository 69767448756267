import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import {
  fetchInitialSettings,
  successInitialSettings,
  errorInitialSettings,
  toggleInitialPopup,
  setBrokerLink,
  setInitialLoading,
} from "./actions";

export default combineReducers({
  isLoading: handleActions(
    {
      [fetchInitialSettings]: () => true,
      [successInitialSettings]: () => false,
      [errorInitialSettings]: () => false,
      [setInitialLoading]: (_, action) => action.payload,
    },
    false
  ),
  isLoaded: handleActions(
    {
      [fetchInitialSettings]: () => false,
      [successInitialSettings]: () => true,
      [errorInitialSettings]: () => false,
    },
    false
  ),
  isError: handleActions(
    {
      [errorInitialSettings]: () => true,
      [fetchInitialSettings]: () => false,
      [successInitialSettings]: () => false,
    },
    false
  ),
  showPopup: handleActions(
    {
      [toggleInitialPopup]: (state, action) => action.payload,
    },
    false
  ),
  data: handleActions(
    {
      [successInitialSettings]: (state, action) => action.payload,
      [setBrokerLink]: (state, action) => {
        if (!action.payload.brokerCode) return state;
        const broker = state["brokers_list_extended"].find((broker) => broker.code === action.payload.brokerCode);

        if (!broker) return state;

        broker.url = action.payload.url;

        return { ...state };
      },
    },
    JSON.parse(
      '{ "error": null, "endpoint": "https://df.vfxalert.com/sse/v1", "defaultpage": "", "popwindow": null, "brokers_list": { "IQoption": "https://iqoption.com/?aff=1884", "Binomo": "https://vfxalert.com/r/?vr=Binomo", "Olymptrade": "https://vfxalert.com/r/?vr=OlympTrade" }, "links": { "home": "https://vfxalert.com/", "e_calendar": "https://vfxalert.com/news/dark", "charts": "https://vfxalert.com/en/charts/dark", "trends": "https://vfxalert.com/trends/nomenu", "docs": "defbrowser::https://vfxalert.com/site/docs3", "gopro": "defbrowser::https://vfxalert.com/en/store/" }, "neparam": 634, "signals-category": { "11": "Crypto Reversal", "21": "Crypto Trending", "10": "Forex Trending ", "20": "Forex Reversal" }, "account_info": { "license": "FREE", "email": "", "username": "", "token": null, "valid": "unlimited", "link_disclaimer": "defbrowser::https://vfxalert.com/site/warning", "link_agreement": "defbrowser::https://vfxalert.com/site/terms", "link_policy": "defbrowser::https://vfxalert.com/site/policy" }, "env_settings": { "free_account_assets": [ "gbpusd", "usdjpy" ], "assets": { "crypto": [ "BTCUSD", "LTCUSD", "ETCUSD", "ETHUSD", "XRPUSD", "BCHUSD" ], "forex": [ "EURUSD", "GBPUSD", "USDCHF", "USDJPY", "AUDUSD", "USDCAD", "EURJPY", "EURGBP", "GBPJPY" ] }, "alg": { "11": "Trending (MA)", "12": "Reversal  (RSI)", "13": "Trending CCI", "14": "Reversal (Parabolic)" }, "expirations": [ 1, 5, 15, 60 ], "debug": false, "user_agent": "", "user_agent_mac": "", "user_agent_win": "" } }'
    )
  ),
});
