import styled from "styled-components";
import BaseAlert from "./BaseAlert";

const ForexWeekendAlert = () => (
  <AlertWrapper>
    <TextItem>ⓘ</TextItem>
    <TextItem>Forex Market closed<br />on weekends</TextItem>
  </AlertWrapper>
)

const AlertWrapper = styled(BaseAlert)`
  background-color: #407BFF;
  margin: 0 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 22px 0;
`;
const TextItem = styled.div`
  color: #FFFFFF;
  display: flex;
  width: 100%;
  justify-content: space-around;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
`;

export default ForexWeekendAlert