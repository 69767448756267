import React from "react";
import styled, { css } from "styled-components";

interface MenuItemProps {
  children: JSX.Element;
  isActive?: boolean;
  badge?: number;
  onClick: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ badge, isActive, children, onClick }) => {
  return (
    <Wrapper active={isActive || false} onClick={onClick}>
      {badge && <Badge>{badge}</Badge>}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ active: boolean }>`
  position: relative;
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ active }) => {
    if (active) {
      return css`
        svg {
          path {
            fill: #ffb800;
          }
        }
      `;
    }
  }}
  &:hover {
    svg {
      path {
        fill: ${({ active }) => (active ? "#FFB800" : "#818695")};
      }
    }
  }
`;

const Badge = styled.span`
  position: absolute;
  top: 1px;
  left: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  background: #1e2432;
  color: #ffb800;
`;

export default MenuItem;
