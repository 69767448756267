import React, { Component } from "react";
import View from "./View";
import { transformValue } from "../../util";

const viewMax = 85;
const viewMin = -85;
const valueMax = 10;
const valueMin = 0;

export default class Zigzag extends Component {
  render() {
    const { value, isNull } = this.props;
    const items = Object.entries(value).map(([id, value], index) => [
      id,
      Math.round(transformValue(value, valueMin, valueMax, viewMin, viewMax))
    ]);
    
    return <View {...{ items }} isNull={isNull}/>;
  }
}
