import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getBrokersList } from "../../../../modules/initial";
import { getCustomBrokersList, isClientPRO } from "../../../../modules/mainSettings";
import { getActiveBroker, getShowConnectBrokerPage, setActiveBroker, newTab, setActiveTab } from "../../../../modules/client";

import View from "./View";
import withLogger from "../../../../hoc/withLogger";

class BrokerSettings extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    availableBrokersList: PropTypes.object.isRequired,
    activeBroker: PropTypes.string,
    isPro: PropTypes.bool.isRequired,
  };

  handleSelectBroker = ({ url, title, code }) => {
    const { newTab, availableBrokersList, setActiveBroker, logger } = this.props;
    const isBroker = availableBrokersList.find((broker) => broker.code === code);

    newTab({ url, title, brokerCode: code });

    if (isBroker) {
      setActiveBroker(code);
    }

    logger.info("user.open.brokerTab", { broker: code });
  };

  handleOpenHelloTab = () => {
    const { setActiveTab, newTab } = this.props;

    newTab({ url: "", title: "Start trading", id: "helloTab" });
    setActiveTab("helloTab");
  };

  render() {
    const { availableBrokersList, isActive, activeBroker, isPro, showConnectBrokersPage } = this.props;

    const customBrokers = isPro ? this.props.customBrokers : [];

    return (
      <View
        isActive={isActive}
        list={availableBrokersList}
        customList={customBrokers}
        activeItem={activeBroker}
        onClick={this.handleSelectBroker}
        onOpenHelloTab={this.handleOpenHelloTab}
        showConnectBrokers={showConnectBrokersPage}
      />
    );
  }
}

export default connect(
  (state) => ({
    availableBrokersList: getBrokersList(state),
    activeBroker: getActiveBroker(state),
    customBrokers: getCustomBrokersList(state),
    isPro: isClientPRO(state),
    showConnectBrokersPage: getShowConnectBrokerPage(state),
  }),
  { setActiveBroker, newTab, setActiveTab }
)(withLogger(BrokerSettings));
