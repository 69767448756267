import React from "react";

const View = ({ items, isNull }) => (
  <div className="graph_bottom_scale">
    {/* <!-- Блок с 4-мя графиками --> */}
    <div className="graph_bottom_scale_box">
      {!isNull && items.map(([id, value], index) => {
        // <div
        //   key={id}
        //   className={`graph_scale graph_scale${index + 1} graph_scale${
        //     value < 0 ? "-" : ""
        //   }${index + 1}${value < 0 ? Math.abs(value) - 1 : Math.abs(value)}`}
        // ></div>
        let symbol = '0';
        if (value > 0) symbol = '';
        if (value < 0) symbol = '-';
        return (
          <div
            key={index}
            className={`graph_scale graph_scale${symbol}${index + 1}`}
            style={{ height: `${Math.abs(value)}px` }}
          ></div>
        );
      })}
      {/*  <!-- Шкала 1 -->
        <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс graph_scale-10-graph_scale15 становится видна -->
        <div class="graph_scale graph_scale-1" style="height: 39px;"></div>
        <!-- Шкала 1 конец -->

        <!-- Шкала 2 -->
        <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс graph_scale-20-graph_scale25 становится видна -->
        <div class="graph_scale graph_scale-2" style="height: 39px;"></div>
        <!-- Шкала 2 конец -->

        <!-- Шкала 3 -->
        <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс graph_scale-30-graph_scale35 становится видна -->
        <div class="graph_scale graph_scale-3" style="height: 39px;"></div>
        <!-- Шкала 3 конец -->

        <!-- Шкала 4 -->
        <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс graph_scale-40-graph_scale45 становится видна -->
        <div class="graph_scale graph_scale-4" style="height: 68px;"></div>
        <!-- Шкала 4 конец -->
      */}
    </div>
    {/* <!-- Блок с 4-мя графиками конец --> */}
  </div>
);

export default View;