import styled from "styled-components";
import { nanoid } from 'nanoid'
import { useState } from "react";

const CheckBox = ({ label, value, onChange, disabled, renderLable, name}) => {
  const [id] = useState(nanoid(12))
  return (
    <Container>
      <Input
        id={id}
        type="checkbox"
        checked={value}
        onChange={onChange}
        disabled={disabled}
        name={name}
      />
      <Label htmlFor={id}>{renderLable ? renderLable() : label}</Label>
    </Container>
  );
};

const Container = styled.div`
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
`;
const Label = styled.label`

`;
const Input = styled.input`
  position: absolute;
  opacity: 0;

  & + ${Label} {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  & + ${Label}::before {
    cursor: pointer;
    content: '';
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.15em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 90% 90%;
  }

  &:checked + ${Label}::before {
    border-color: #2275D7;
    background-color: #2275D7;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 12'%3e%3cpath d='M5.83333 11.6667L0 6.05769L1.63333 4.48718L5.83333 8.52564L14.7 0L16.3333 1.57051L5.83333 11.6667Z'/%3e%3c/svg%3e");
  }

  &:disabled + ${Label} {
    opacity: 0.3;
  }
`;
// <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
//   <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83333 11.6667L0 6.05769L1.63333 4.48718L5.83333 8.52564L14.7 0L16.3333 1.57051L5.83333 11.6667Z" fill="#EEEEEE"/>
// </svg>

export default CheckBox;