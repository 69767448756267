import React from "react";
import styled from "styled-components";

const gradientColor = (val) => {
  const div = val - 50;
  const perc = (100 * div) / 50;
  const index = Math.floor(perc / 10);
  let rez = index.toString();

  if (index == 0 && div < 0) rez = "-0";
  if (index > 5) rez = "5";
  if (index < -5) rez = "-5";

  return "col" + rez;
};


const BinaryVariant = ({ signal, price, updatedServerSync, expiration, alg, showFull, hm }) => (
  <>
    <PriceContainer>
      <p className={`money_how_much ${signal === -1 ? "red" : "green"}`}>
        {price}
      </p>
      <div
        className={`money_how_much_icon money_how_much_icon${
          signal === -1 ? "1" : "2"
        }`}
      ></div>
    </PriceContainer>
    <MetaContainer>
      <MetaItem>
        <div className="time_icon time_icon1"></div>
        {/* <p className="time_text">{updated}</p> */}
        <p className="time_text">{updatedServerSync}</p>
      </MetaItem>
      <MetaItem>
        <div className="time_icon time_icon2"></div>
        <p className="time_text">{expiration} min</p>
      </MetaItem>
      <MetaItem>
        <div className="time_icon time_icon3"></div>
        <p className="time_text">{alg}</p>
      </MetaItem>
    </MetaContainer>
  </>
);

export default BinaryVariant;

const MetaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const MetaItem = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    width: 55px;
  }
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`;