import styled, { css } from "styled-components";

const Button = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  //padding: 8px 24px;
  background-color: #2275d7;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    ${(props) =>
      !props.disabled
        ? css`
            opacity: 0.85;
          `
        : ""}
    cursor: pointer;
  }

  ${(props) => {
    const size = props.size ? props.size : 12;
    return css`
      font-size: ${size}px;
    `;
  }}
  ${(props) =>
    props.isWarning
      ? css`
          background-color: #ff3d71;
        `
      : ""}
  ${(props) =>
    props.disabled
      ? css`
          background-color: #232938;
          cursor: default;
          color: #777;
        `
      : ""}
`;

export default Button;
