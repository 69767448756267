import React from "react";
import styled from "styled-components";
import SubItem from "./SubItem";
import Payment from "./Payment";
import Button from "../../../../../base/form/Button";

const SubscriptionView = (props) => {
  const { subscriptions, payments, checkedSubId, checkedPaymentId, onSubChange, onPaymentChange, onBuyClicked } = props;
  return (
    <Container>
      <SubsContainer>
        {subscriptions.map(({ id, title, headerColor, description }) => (
          <SubItem
            key={id}
            id={id}
            title={title}
            headerColor={headerColor}
            description={description}
            onSubChange={onSubChange}
            checked={id === checkedSubId}
          />
        ))}
      </SubsContainer>
      <PaymentContainer>
        {payments.map(({ id, label, image }) => (
          <Payment key={id} id={id} checked={checkedPaymentId === id} label={label} onPaymentChange={onPaymentChange} image={image} />
        ))}
        <StyledButton onClick={onBuyClicked}>Buy license</StyledButton>
      </PaymentContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 20px;
`;

const SubsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
`;

const PaymentContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 40px;
`;

const StyledButton = styled(Button)`
  height: 40px;
  width: 180px;
  align-self: flex-end;
`;

export default SubscriptionView;
