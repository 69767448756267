import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import Tab from "./Tab";

const TabPanel = ({ tabs, activeTabId, onTabSelect, onTabClose }) => {
  return (
    <Panel id={"tabs-panel"}>
      {tabs.map((tabItem) => {
        if (tabItem.id === "helloTab") return null;

        return <Tab key={tabItem.id} {...tabItem} isActive={tabItem.id === activeTabId} onSelect={onTabSelect} onClose={onTabClose} />;
      })}
      <EmptySpace />
    </Panel>
  );
};

const EmptySpace = () => {
  const ref = useRef(null);

  const [roundBorders, setRoundBorders] = useState(false);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const lastTab = ref.current.previousElementSibling;

    if (lastTab && lastTab.dataset.active === "true") {
      setRoundBorders(true);
    } else {
      setRoundBorders(false);
    }
  });

  return <EmptySpaceComponent ref={ref} data-empty roundBorders={roundBorders} />;
};

const Panel = styled.div`
  display: flex;
  background: #3a4255;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
`;

const EmptySpaceComponent = styled.div`
  flex: 2 0 auto;
  background: #232938;
  border-top-left-radius ${({ roundBorders }) => (roundBorders ? "6px" : "0")};
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  box-sizing: border-box;
  transition: none;
`;

export default TabPanel;
