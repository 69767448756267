export const transformValue = (value, sourceMin, sourceMax, destMin, destMax) =>
  ((value - sourceMin) / (sourceMax - sourceMin)) * (destMax - destMin) +
  destMin;

export const buildDashboardValues = (source) => {
  if (!source) {
    return {
      summary: {
        pwr: null,
        bulls: null,
        bears: null,
        rsi: null,
        cci: null,
        trends: null
      },
      piv: null,
      volatility: null,
      zigzag: {
        a: null,
        b: null,
        c: null,
        d: null,
      },  
    }
  }
  
  const { zza, zzb, zzc, zzd, pp, vol, rsi, cci, bulls, bears, isummary, trends } = source;
  return {
    summary: {
      pwr: transformValue(parseFloat(isummary), 0, 10, -10, 10),
      bulls,
      bears,
      rsi,
      cci,
      trends
    },
    piv: transformValue(parseFloat(pp), 0, 10, -5, 5),
    volatility: parseFloat(vol),
    zigzag: {
      a: parseFloat(zza),
      b: parseFloat(zzb),
      c: parseFloat(zzc),
      d: parseFloat(zzd),
    },
  };
};
