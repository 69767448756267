import React, { Component } from "react";
import { connect } from "react-redux";
import View from "./View";
import { getAccountInfoLinks, getLinks } from "../../../../modules/initial";
import { openLink as openLinkUtil } from "../../../../utils";

class VersionInfo extends Component {
  openLink = (url) => {
    openLinkUtil({ url });
  };

  render() {
    return <View openLink={this.openLink} {...this.props.accountLinks} {...this.props.links} isActive={this.props.isActive} />;
  }
}

export default connect((state) => ({
  accountLinks: getAccountInfoLinks(state),
  links: getLinks(state),
}))(VersionInfo);
