import React, { Component } from "react";
import PropTypes from "prop-types";
import VersionInfo from "./items/VersionInfo";
import AppSettings from "./items/AppSettings";
import Initial from "./items/Initial";
import Tutorial from "./items/Tutorial";

export default class PopupWindows extends Component {
  static propTypes = {
    // showVersionInfo: PropTypes.bool.isRequired,
    // showAppSettings: PropTypes.bool.isRequired,
    appSettings: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
    versionInfo: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
    tutorial: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
  };

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.appSettings.isActive !== this.props.appSettings.isActive) {
      const data = {
        event: "settings",
        status: nextProps.appSettings.isActive ? "open" : "close",
      };

      console.log("sent to native:", data);
      if (window.vfx_sent_to_native) {
        window.vfx_sent_to_native(JSON.stringify(data));
      }
    }

    return true;
  }

  render() {
    const { versionInfo, appSettings, tutorial } = this.props;
    return (
      <>
        <VersionInfo {...versionInfo} />
        <AppSettings {...appSettings} />
        <Tutorial {...tutorial} />
        <Initial />
      </>
    );
  }
}
