import React from "react";
import styled from "styled-components";

const Slide = ({ image, title, description, buttonText, onButtonClick }) => {
  return (
    <Wrapper>
      <Image image={image} />
      <TextWrapper>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        {buttonText && onButtonClick && <Button onClick={onButtonClick}>{buttonText}</Button>}
      </TextWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Image = styled.div`
  height: 450px;
  overflow: hidden;
  border-radius: 5px;
  background: ${({ image }) => `url(${image}) top/cover`};
  margin-bottom: 25px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  text-align: center;
  //margin-bot  tom: 30px;
`;

const Title = styled.p`
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
`;

const Description = styled.span`
  color: #fff;
  font-size: 16px;
`;

const Button = styled.button`
  border: none;
  background: linear-gradient(180deg, #ffad7e 0%, #ff8540 26.56%, #db743a 100%);
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  box-shadow: 0 0 7px #ff975e, 0 2px 30px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export default Slide;
