import React, { useRef } from "react";
import styled, { css } from "styled-components";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Window from "../../../base/modal/Window";
import Slide from "./Slide";
import { ReactComponent as ArrowLeftSvg } from "./assets/arrow-left.svg";
import { ReactComponent as ArrowRightSvg } from "./assets/arrow-right.svg";

SwiperCore.use([Navigation]);

const TutorialView = ({ isActive, handleClick, steps, activeIndex, onChange }) => {
  const prevArrow = useRef(null);
  const nextArrow = useRef(null);

  return (
    <Window
      {...{
        isActive,
        width: 1034,
        height: 670,
        handleClose: handleClick,
        enableClose: true,
        hideMenu: false,
        background: "#232938",
        overlayMode: "light",
      }}
    >
      <Wrapper>
        <div>
          <Swiper
            onSlideChange={({ activeIndex }) => onChange(activeIndex)}
            navigation={{
              prevEl: prevArrow.current,
              nextEl: nextArrow.current,
            }}
          >
            {steps.map((slide, i) => {
              return (
                <SwiperSlide key={i}>
                  <Slide {...slide} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        <ControlsWrapper>
          <ArrowLeft isdisabled={(activeIndex === 0).toString()} ref={prevArrow} />
          <ArrowRight isdisabled={(activeIndex === steps.length - 1).toString()} ref={nextArrow} />
        </ControlsWrapper>
      </Wrapper>
    </Window>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 25px 25px 25px 25px;
  justify-content: space-between;
`;

const ControlsWrapper = styled.div`
  display: flex;
  width: 100px;
  margin: 0 auto;
  justify-content: space-between;
`;

const ArrowControlIcon = (isDisabled) =>
  isDisabled
    ? css`
        path {
          fill: #4a5b86;
        }
      `
    : "";

const ArrowLeft = styled(ArrowLeftSvg)`
  ${({ isdisabled }) => ArrowControlIcon(isdisabled === "true")};
`;

const ArrowRight = styled(ArrowRightSvg)`
  ${({ isdisabled }) => ArrowControlIcon(isdisabled === "true")};
`;

export default TutorialView;
