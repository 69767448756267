import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { addLogAction, clearLogs } from "./actions";
import { Logs, LogType } from "./types";

export default combineReducers<Logs>({
  list: handleActions<LogType[], LogType>(
    {
      [`${addLogAction}`]: (state, { payload }) => [...state, { ...payload }],
      [`${clearLogs}`]: () => [],
    },
    []
  ),
});
