import React, { Component } from "react";
import TabContent from "./TabContent";
import StartTabContent from "./StartTabContent";
import TabContentWrapper from "./TabContentWrapper";
import { connect } from "react-redux";
import {
  getTabs,
  closeTab,
  getActiveTabId,
  setActiveTab,
  getActiveBroker,
  setTabTitle,
  setActiveBroker,
  newTab,
  getShowConnectBrokerPage,
} from "../../modules/client";
import { getBrokersList, getLinks, getUserAgent, isInitialLoading } from "../../modules/initial";
import TabsPanel from "./TabsPanel";

const HELLO_TAB_ID = "helloTab";

class WebTabsPanel extends Component {
  componentDidMount() {
    this.check();
  }

  componentDidUpdate() {
    this.check();
  }

  check() {
    const { activeTabId, newTab, showConnectBrokersPage, availableBrokersList, closeTab, setActiveBroker } = this.props;
    if (activeTabId === HELLO_TAB_ID && !showConnectBrokersPage) {
      let broker = availableBrokersList?.find((broker) => broker["user_has_account"]);

      if (!broker && availableBrokersList && availableBrokersList.length) {
        broker = availableBrokersList[0];
      }

      if (!broker) return;

      newTab({ url: broker.url, brokerCode: broker.code, title: broker.title });
      setActiveBroker(broker.code);
      closeTab("helloTab");
    }
  }

  handleTabSelect = (id) => {
    if (this.props.activeTabId === id) return;
    const tab = this.props.tabs.find((tab) => tab.id === id);
    if (tab?.brokerCode) {
      this.props.setActiveBroker(tab.brokerCode);
    }
    this.props.setActiveTab(id);
  };

  handleTabClose = (id) => {
    this.props.closeTab(id);
  };

  render() {
    const { tabs, isResizing, activeTabId, setTabTitle, userAgentPreset, showConnectBrokersPage } = this.props;

    return (
      <>
        <TabsPanel tabs={tabs} activeTabId={activeTabId} onTabSelect={this.handleTabSelect} onTabClose={this.handleTabClose} />
        <div id={"live-notification-container"} />
        <TabContentWrapper>
          {tabs.map(({ url, id, title, brokerCode }) => (
            <>
              {id === "helloTab" && showConnectBrokersPage ? (
                <StartTabContent
                  key={id + title}
                  url={"/localPages/connectBroker/index.html"}
                  isResizing={isResizing}
                  isActive={HELLO_TAB_ID === activeTabId}
                />
              ) : (
                <TabContent
                  key={id + title}
                  tabId={id}
                  url={url}
                  hasTitle={!!title}
                  isResizing={isResizing}
                  brokerCode={brokerCode || null}
                  isActive={id === activeTabId}
                  setTabTitle={setTabTitle}
                  userAgent={userAgentPreset}
                />
              )}
            </>
          ))}
        </TabContentWrapper>
      </>
    );
  }
}

export default connect(
  (state) => ({
    tabs: getTabs(state),
    activeTabId: getActiveTabId(state),
    activeBroker: getActiveBroker(state),
    availableBrokersList: getBrokersList(state),
    links: getLinks(state),
    userAgentPreset: getUserAgent(state),
    showConnectBrokersPage: getShowConnectBrokerPage(state),
    isInitialLoading: isInitialLoading(state),
  }),
  { closeTab, setActiveTab, setTabTitle, setActiveBroker, newTab }
)(WebTabsPanel);
