import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { receiveIndicators, receiveInitialIndicators } from "./actions";

export default combineReducers({
  list: handleActions(
    {
      // { list: array, period: int } = payload
      // [receiveIndicators]: (state, { payload }) => {
      //   const otherPeriods = state.filter(
      //     ({ period }) => payload.period !== parseInt(period)
      //   );
      //   return otherPeriods.concat(payload.list);
      // },
      [receiveIndicators]: (state, action) => action.payload,
      [receiveInitialIndicators]: (state, action) => action.payload
    },
    []
  ),
});
