import { fetchInitialSettings as fetchAction, successInitialSettings, errorInitialSettings } from "./actions";
import { receiveInitialSignals } from "../signals";
import { receiveInitialIndicators } from "../indicators";
import { requestInitialSettings } from "../api";
import { getLicenseKey } from "../mainSettings";
import { resetBrokerIntegrationList, updateTms } from "../client";
import { connectSocket } from "../socket";
import { initDashboard } from "../dashboard";
import { fetchSubscriptionsThunk } from "../subscriptions";

export const fetchInitialSettings = () => async (dispatch, getState) => {
  dispatch(fetchAction());
  dispatch(fetchSubscriptionsThunk());
  const lkey = getLicenseKey(getState());
  try {
    const { data } = await requestInitialSettings({ lkey });

    dispatch(updateTms(data.tms));
    dispatch(successInitialSettings(data));
    dispatch(receiveInitialSignals([...data.signals]));
    dispatch(receiveInitialIndicators([...data.indicators]));
    dispatch(initDashboard([...data?.env_settings?.free_account_assets]));
    dispatch(resetBrokerIntegrationList());

    if (process.env.NODE_ENV === "production" && data?.env_settings?.debug === true) {
      window.Sentry.init({
        dsn: "https://efbfb30eeb0643cf8337eeaf30f96ae4@o348725.ingest.sentry.io/5236143",
      });
      console.log("debug tool connected");
    }

    const { host, channel } = data["ws-config"];
    dispatch(connectSocket({ host, channel }));

    const filteredEntries = Object.entries(data["account_info"]).reduce((res, [key, value]) => {
      if (value !== null) {
        res.push([key, value]);
      }

      return res;
    }, []);

    const accountInfo = Object.fromEntries(filteredEntries);

    const dataToSent = {
      event: "user_info",
      ...accountInfo,
    };

    console.log("sent to native:", dataToSent);

    if (window.vfx_sent_to_native) {
      window.vfx_sent_to_native(JSON.stringify(dataToSent));
    }
  } catch (e) {
    console.error(e);
    dispatch(errorInitialSettings());
  }
};
