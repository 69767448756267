import Window from "../../../base/modal/Window";
import styled, { css } from "styled-components";
import lockIcon from "../../../base/assets/icon-lock.svg";

const MenuItem = ({ title, icon, onSelect, isActive, isBlocked }) => {
  return (
    <MenuItemWrapper onClick={onSelect} isActive={isActive} isBlocked={isBlocked}>
      <MenuItemIcon icon={icon} isActive={isActive} />
      <MenuItemText>{title}</MenuItemText>
      {isBlocked && (
        <MenuItemLock>
          <MenuItemLockOverlay />
          <MenuItemLockImg />
        </MenuItemLock>
      )}
    </MenuItemWrapper>
  );
};

const View = ({ isActive, children, tabs, onTabClick, handleClick, isPro, isAuth }) => {
  tabs.sort((a, b) => a.id - b.id);
  return (
    <Window {...{ isActive, title: "Settings", width: 1034, height: 520, handleClose: handleClick, enableClose: isAuth, hideMenu: !isAuth }}>
      <Wrapper>
        <Menu>
          {tabs
            .filter(({ disabled }) => !disabled)
            .map(({ id, name, isActive, icon, onlyPro }) => (
              <MenuItem
                isActive={isActive}
                key={id}
                title={name}
                icon={icon}
                onSelect={() => onTabClick(id)}
                isBlocked={!isPro && onlyPro}
              />
            ))}
          {!isPro && <NotProText>To activate additional features buy a license</NotProText>}
        </Menu>
        <Content>{children}</Content>
      </Wrapper>
    </Window>
  );
};

const Menu = styled.div`
  width: 170px;
  height: 100%;
  box-shadow: 0.5px 0 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;
const MenuItemIcon = styled.div`
  width: 24px;
  height: 24px;
  ${(props) => css`
    background-color: ${props.isActive ? "#3366FF" : "#EBEBF5"};
    mask-image: url(${props.icon});
  `}
`;
const MenuItemText = styled.div`
  margin-left: 5px;
  font-size: 15px;
  font-weight: 500;
`;
const MenuItemWrapper = styled.div`
  height: 60px;
  width: 100%;
  padding-left: 20px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    ${(props) => css`
      cursor: ${props.isBlocked ? "default" : "pointer"};
      ${!props.isBlocked ? "opacity: 0.9; background: rgba(51, 102, 255, 0.08);" : ""}
    `}
  }
  ${(props) => css`
    color: ${props.isActive ? "#3366FF" : "#EBEBF5"};
    opacity: ${props.isActive || props.isBlocked ? "1" : "0.6"};
    background: ${props.isActive ? "rgba(51, 102, 255, 0.08)" : ""};
  `}
`;
const MenuItemLock = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  left: 0;
`;
const MenuItemLockOverlay = styled.div`
  background-color: #2d364e;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  position: absolute;
  z-index: 0;
`;
const MenuItemLockImg = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${lockIcon});
  z-index: 0;
`;
const NotProText = styled.div`
  color: #ffffff;
  opacity: 0.9;
  font-size: 11px;
  padding: 10px 10px;
  text-align: center;
  line-height: 15px;
`;
const Content = styled.div`
  width: calc(100% - 170px);
  padding: 15px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export default View;
