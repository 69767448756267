import React, { useState, useRef } from "react";
import styled from "styled-components";

const Tooltip = ({ text, children }) => {
  const isMouseEnter = useRef(false);
  const [hidden, setHidden] = useState(true);

  const handleMouseEnter = () => {
    isMouseEnter.current = true;
    setTimeout(() => {
      if (isMouseEnter.current) {
        setHidden(false);
      }
    }, 500);
  };

  const handleMouseLeave = () => {
    isMouseEnter.current = false;
    setTimeout(() => {
      if (!hidden) {
        setHidden(true);
      }
    }, 500);
  };

  return (
    <Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <TooltipWrapper hidden={hidden}>
        <TooltipText>{text}</TooltipText>
      </TooltipWrapper>
      {children}
    </Container>
  );
};

const TooltipWrapper = styled.div`
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
  align-items: center;
  position: absolute;
  bottom: -18px;
  left: -5px;
  //background: rgba(0, 0, 0, 0.7);
  //background: rgba(58, 66, 85, 0.8);
  background: rgba(67, 71, 90, 1);
  border-radius: 5px;
  padding: 3px 5px;
  white-space: nowrap;
`;

const Container = styled.div`
  position: relative;
`;

const TooltipText = styled.span`
  font-size: 10px;
  color: white;
`;

export default Tooltip;
