import openBrokerChart from "./events/openBrokerChart";
import openBrokerPosition from "./events/openBrokerPosition";
import toggleVisibilityTabContent from "./events/toggleVisibilityTabContent";
import openSubscriptionSettings from "./events/openSubscriptionSettings";

export default {
  openBrokerChart,
  openBrokerPosition,
  toggleVisibilityTabContent,
  openSubscriptionSettings,
};
