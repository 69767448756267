import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addCustomBrokersItem,
  removeCustomBrokersItem,
  getCustomBrokersList,
  getLicenseKey,
  getClientRate,
  setLicenseKey,
  removeLicenseKey,
  isClientPRO,
  getLicenseKeyError
} from "../../../../../../modules/mainSettings";

import View from "./View";

const defaultState = {
  showBrokersItemForm: false,
  newBrokerTitle: "",
  newBrokerUrl: "",
  newLicenseKey: "",
  brokerUrlError: false,
  login: "",
  password: ""
};

const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (e) {
    return false;  
  }
}

class MainTab extends Component {
  state = {
    ...defaultState,
  };

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, brokerUrlError: false });
  };

  handleBrokersItemForm = () => {
    this.setState({ showBrokersItemForm: !this.state.showBrokersItemForm });
  };

  handleBrokersItemAdd = (e) => {
    e.preventDefault();

    const { newBrokerTitle, newBrokerUrl } = this.state;

    if (newBrokerTitle.length && newBrokerUrl.length && isValidUrl(newBrokerUrl)) {
      this.props.addCustomBrokersItem({
        title: newBrokerTitle,
        url: newBrokerUrl,
      });
      this.setState({ ...defaultState });
    } else { 
      this.setState({ brokerUrlError: true });
    }
  };

  render() {
    return (
      <View
        {...this.state}
        {...this.props}
        onFormFieldChange={this.handleInputChange}
        handleBrokersItemForm={this.handleBrokersItemForm}
        handleBrokersItemAdd={this.handleBrokersItemAdd}
      />
    );
  }
}

export default connect(
  (state) => ({
    licenseKey: getLicenseKey(state),
    brokersList: getCustomBrokersList(state),
    clientRate: getClientRate(state),
    isPro: isClientPRO(state),
    isLicenseKeyError: getLicenseKeyError(state)
  }),
  {
    addCustomBrokersItem,
    removeCustomBrokersItem,
    setLicenseKey,
    removeLicenseKey,
  }
)(MainTab);
