import React from "react";
import styled from "styled-components";
import Button from "../../../base/form/Button";

const renderBrokerItem = (code, title, url, index, onClick, activeItem, showUrl = false) => {
  return (
    <div
      className="signal_type_item"
      key={index}
      onClick={(e) => {
        onClick({ url, title, code });
      }}
    >
      {/* <!-- Элемент Signal Type с галочкой --> */}
      {/* <!-- Изначально выглядит пассивным. При добавлении класса active становится активным. Галочка становится  --> */}
      <div className={`signal_type_name_box ${activeItem === code ? "active" : ""}`}>
        <span></span>
        <div className="signal_type_name">{title}</div>
      </div>
      {/* <!-- Элемент Signal Type с галочкой конец --> */}

      {/* <!-- Цифровые значения Signal Type --> */}
      {/* <!-- Изначально все элементы пассивные. При добавлении класса active становится активным. Цифра обводится в круглешок и становится белой  --> */}
      {showUrl && (
        <a
          href={url}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {url}
        </a>
      )}
      {/* <!-- Цифровые значения Signal Type конец --> */}
    </div>
  );
};

const View = ({ isActive, list, customList, activeItem, onClick, onOpenHelloTab, showConnectBrokers }) => {
  return (
    <>
      {/* <!-- Список тип сигнала --> */}
      {/* <!-- Изначально скрыт, появляется при добавлении класса active --> */}
      <div className={`signal_type ${isActive ? "active" : ""}`}>
        <div className="signal_type_title">Brokers</div>
        <div className="signal_type_wrap">
          {list &&
            list.length > 0 &&
            list.map(({ code, title, url }, index) => renderBrokerItem(code, title, url, index, onClick, activeItem))}
          {customList &&
            customList.length > 0 &&
            customList.map(({ title, url }, index) => renderBrokerItem(title, title, url, `${index}_2`, onClick, activeItem, true))}
          {/*{showConnectBrokers && <StyledButton onClick={onOpenHelloTab}>Connect with brokers</StyledButton>}*/}
        </div>
      </div>
      {/* <!-- Список тип сигнала конец --> */}
    </>
  );
};

const StyledButton = styled(Button)`
  height: 30px;
  width: 80%;
  margin: 0 auto;
`;

export default View;
