import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  getPopup,
  isPopupShow,
  isInitialLoaded,
  toggleInitialPopup,
} from "../../../../modules/initial";

class Initial extends Component {
  static propTypes = {
    toggleInitialPopup: PropTypes.func.isRequired,
    isInitialLoaded: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      url: PropTypes.string.isRequired,
      h: PropTypes.number.isRequired,
      w: PropTypes.number.isRequired,
    }),
  };

  render() {
    const { isInitialLoaded, data, toggleInitialPopup, isActive } = this.props;
    if (!isActive || !isInitialLoaded || !data) return null;

    return (
      <div className={`modal_overlay active`}>
        <div
          className="modal"
          style={{ width: data.w, height: data.h, padding: 0 }}
        >
          <div
            className="modal_close_btn"
            onClick={() => {
              toggleInitialPopup(false);
            }}
          ></div>
          <webview
            ref={(webview) => {
              if (!!webview) {
                // webview.addEventListener('did-stop-loading', (e) => {
                //   console.log("stop loading", e);
                // })
                // webview.addEventListener('will-navigate', (e) => {
                //   console.log("navigate", e);
                // })
                webview.addEventListener("new-window", (e) => {
                  if (e.url.indexOf("vfxaction=newwnd") > -1)
                    window.shell.openExternal(e.url);
                  else webview.src = e.url;
                });
              }
            }}
            src={data.url}
            autosize="on"
            style={{ height: "100%" }}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    data: getPopup(state),
    isInitialLoaded: isInitialLoaded(state),
    isActive: isPopupShow(state),
  }),
  { toggleInitialPopup }
)(Initial);
