import React from "react";
import styled from "styled-components";
import Input from "../../../../../base/form/Input";
import Button from "../../../../../base/form/Button";
import iconDefSettings from "../../../../../base/assets/icon-default-settings.svg";
import { openLink } from "../../../../../../utils";
import ErrorMessage from "../../../../../base/form/ErrorMessage";
import Loader from "../../../../../base/Loader";

const View = ({
  isActive,
  isAuth,
  link,
  handleApplyLicenseKey,
  onFormFieldChange,
  clientRate,
  licenseKey,
  userEmail,
  removeLicenseKey,
  onApplyDefault,
  isLicenseKeyError,
  isLoading,
  login,
  password,
}) => {
  return (
    <Container>
      <AccountTitle>vfxAlert Account</AccountTitle>
      <FormApply onSubmit={handleApplyLicenseKey}>
        {isAuth ? (
          <Input
            label={"Email"}
            image={"mail"}
            type="text"
            disabled={userEmail && userEmail.length > 0}
            value={userEmail}
            name="userEmail"
            onChange={onFormFieldChange}
          />
        ) : (
          <>
            <Input label={"Enter email"} image={"mail"} type="text" value={login} name="login" onChange={onFormFieldChange} />
            <Input label={"Enter password"} image={"lock"} type="password" value={password} name="password" onChange={onFormFieldChange} />
          </>
        )}
        {/* this button is needed to submit the form by pressing enter */}
        <button type={"submit"} style={{ display: "none" }} />
        <ApplyWrapper>
          <ApplyButton type={"submit"} onClick={handleApplyLicenseKey} disabled={isAuth || isLoading}>
            {isLoading ? <Loader /> : "Apply"}
          </ApplyButton>
          <ErrorContainer>{isLicenseKeyError && <ErrorMessage errorText="You entered an invalid or non-existent account" />}</ErrorContainer>
        </ApplyWrapper>
      </FormApply>
      <RateContainer>
        <RateTitle>Your Rate</RateTitle>
        <RateText>{clientRate ? clientRate.toUpperCase() : "FREE"}</RateText>
        {(!licenseKey || !licenseKey.length) && (
          <RateButton size={13} onClick={() => openLink(`${link}?utm_source=upgrade_pro_app`, () => {})}>
            Buy PRO
          </RateButton>
        )}
        {licenseKey && licenseKey.length > 0 && <RateButton onClick={removeLicenseKey}>Logout</RateButton>}
      </RateContainer>
      <DefaultSettingsButton onClick={onApplyDefault}>
        <ImgDefSettings src={iconDefSettings} />
        Apply default settings
      </DefaultSettingsButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 15px;
  height: 100%;
  width: 400px;
`;
const AccountTitle = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 17px;
`;
const FormApply = styled.form`
  padding: 40px 0 25px;
`;
const ApplyWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const ApplyButton = styled(Button)`
  width: 100px;
  padding: 10px 0;
`;
const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 85px;
`;
const RateButton = styled(Button)`
  width: 200px;
  padding: 10px 0;
`;
const RateTitle = styled.div`
  color: rgba(255, 255, 255, 0.54);
  opacity: 0.5;
  font-size: 14px;
`;
const RateText = styled.div`
  font-size: 14px;
  color: #fff;
  font-weight: 700;
`;
const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
`;
const DefaultSettingsButton = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: #407bff;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
`;
const ImgDefSettings = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;

export default View;
