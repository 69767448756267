import eventBus from "../eventBus";

const eventName = "openBrokerChart";

const subscribe = (callback) => {
  return eventBus.subscribe(eventName, callback);
};

const emit = (data) => {
  return eventBus.emit(eventName, data);
};

export default {
  subscribe,
  emit,
};
