import { connect } from "react-redux";
import styled from "styled-components";
import Signal from "../../../../LeftPanel/items/Signal/View";
import { setSignalView, getSignalView } from "../../../../../modules/client";
import RadioSelector from "../../../../base/form/RadioSelector";

const staticSignal = {
  showFull: true,
  updatedServerSync: "01:27",
  symbol: "EURUSD",
  timestamp: 0,
  expiration: 0,
  signal: 1,
  alg: 14,
  price: 1.29001,
  power: 3,
  hm: {
    m1: 40,
    m5: 50,
    m15: 60,
    m30: 70,
    h1: 80,
    h4: 90,
  },
  take_profit: 200,
  stop_loss: 300,
  symbol_point: 0.00001,
  onSetSymbolClicked: () => {},
  onOpenChartsClicked: () => {},
};

const SignalViewTab = ({ variant, setSignalView }) => {
  return (
    <Container>
      {[1, 4].map((value) => (
        <RadioSelector
          LabelComponent={SignalContainer}
          key={`radio_signal_view_${value}`}
          id={`radio_signal_view_${value}`}
          checked={value === variant}
          onChange={() => {
            setSignalView(value);
          }}
        >
          <SignalContainer>
            <Signal {...staticSignal} variant={value} isStatic={true}/>
          </SignalContainer>
        </RadioSelector>
      ))}
    </Container>
  );
};

export default connect((state) => ({
    variant: getSignalView(state),
  }),
  { setSignalView },
)(SignalViewTab);

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  justify-content: space-between;
`;
const SignalContainer = styled.div`
  width: 205px;
  opacity: 0.5;
`;