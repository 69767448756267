import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import {
  setTerm,
  setSymbol,
  setVisibleSettings,
  setEnabled,
  initDashboard,
} from "./actions";

const generateDashboard = (symbol, enabled) => ({
  enabled: enabled,
  term: 5,
  symbol: symbol.toUpperCase(),
  visible: {
    summary: true,
    zigzag: true,
    piv: true,
    volatility: true,
  },
});

const setDashboardValue = (key) => (state, { payload }) => {
  const newState = [...state];
  const dasboardItem = newState.find((item, index) => index === payload.dashboardId);

  if (dasboardItem) dasboardItem[key] = payload?.value;

  return newState;
};

const items = handleActions(
  {
    [initDashboard]: (state, { payload }) => {
      if (state[7]) return state;

      console.log("init dashboard", payload);
      return [
        ...payload.map(symbol => generateDashboard(symbol, true)),
        ...[2, 3, 4, 5, 6, 7].map(symbol => generateDashboard(payload[0], false)),
      ];
    },

    [setEnabled]: setDashboardValue("enabled"),
    [setTerm]: setDashboardValue("term"),
    [setSymbol]: setDashboardValue("symbol"),
    [setVisibleSettings]: setDashboardValue("visible"),
  },
  [],
);

export default combineReducers({
  items,
});
