import React, { Component } from "react";
import PropTypes from "prop-types";

import SignalsList from "./items/SignalsList";
import BrokerSettings from "./items/BrokerSettings";
import TypeSettings from "./items/TypeSettings";
import TelegramMessages from "./items/TelegramMessages";

export default class LeftPanel extends Component {
  static propTypes = {
    showSignalsList: PropTypes.bool.isRequired,
    showBrokerSettings: PropTypes.bool.isRequired,
    showTelegramMessages: PropTypes.bool.isRequired,
    typeSettings: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
  };

  render() {
    const { showSignalsList, typeSettings, showBrokerSettings, showTelegramMessages } = this.props;

    return (
      <>
        <SignalsList isActive={showSignalsList || showBrokerSettings || typeSettings.isActive} />
        <BrokerSettings isActive={showBrokerSettings} />
        <TypeSettings {...typeSettings} />
        <TelegramMessages isActive={showTelegramMessages} />
      </>
    );
  }
}
