import React from "react";
import styled from "styled-components";

const DescriptionItem = ({ label, value }) => {
  const color = label.toLowerCase() === "price" ? "#fff" : "rgba(255, 255, 255, 0.6)";

  return (
    <Wrapper>
      <Label>{label}</Label>
      <Value style={{ color }}>{value}</Value>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.span`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 3px;
`;

const Value = styled.p`
  font-size: 18px;
  font-weight: 700;
`;

export default DescriptionItem;
