import styled from "styled-components";
import RadioSelector from "../../../../../base/form/RadioSelector";

const Selector = ({ children, ...rest }) => (
  <RadioSelector LabelComponent={StyledRadioLabelText} {...rest}>
    {children}
  </RadioSelector>
);

const View = ({ settingsIndex, onChangeValue }) => {
  return (
    <Container>
      <Selector
        id="radio"
        checked={settingsIndex === 1}
        onChange={() => {
          onChangeValue(1);
        }}
      >
        <StyledRadioLabelText style={{ width: 111, height: 47, top: 34, left: -30, transform: "rotate(-90deg)" }}>
          Signals type
        </StyledRadioLabelText>
        <StyledRadioLabelText style={{ width: 167, height: 80, top: 2, left: 51 }}>
          Broker's window
        </StyledRadioLabelText>
        <StyledRadioLabelText style={{ width: 167, height: 29, top: 84, left: 51 }}>Dashboards</StyledRadioLabelText>
      </Selector>
      <Selector
        id="radio1"
        checked={settingsIndex === 2}
        onChange={() => {
          onChangeValue(2);
        }}
      >
        <StyledRadioLabelText style={{ width: 216, height: 80, top: 2, left: 0 }}>Broker's window</StyledRadioLabelText>
        <StyledRadioLabelText style={{ width: 216, height: 29, top: 84, left: 0 }}>Dashboards</StyledRadioLabelText>
      </Selector>
      <Selector
        id="radio2"
        checked={settingsIndex === 3}
        onChange={() => {
          onChangeValue(3);
        }}
      >
        <StyledRadioLabelText style={{ width: 111, height: 47, top: 34, left: -30, transform: "rotate(-90deg)" }}>
          Signals type
        </StyledRadioLabelText>
        <StyledRadioLabelText style={{ width: 167, height: 111, top: 2, left: 51 }}>
          Broker's window
        </StyledRadioLabelText>
      </Selector>
      <Selector
        id="radio3"
        checked={settingsIndex === 4}
        onChange={() => {
          onChangeValue(4);
        }}
      >
        <StyledRadioLabelText style={{ width: 216, height: 111, top: 2, left: 0 }}>
          Broker's window
        </StyledRadioLabelText>
      </Selector>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  flex-wrap: wrap;
  width: 550px;
`;
const StyledRadioLabelText = styled.div`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.65;
  color: #407BFF;
  background: #232938;
  border-radius: 3px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export default View;