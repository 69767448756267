import { combineReducers } from "redux";
import { handleActions } from "redux-actions";

import { fetchSubscriptions, fetchSubscriptionsSuccess, fetchSubscriptionFailure } from "./actions";

export default combineReducers({
  list: handleActions(
    {
      [fetchSubscriptionsSuccess]: (state, action) => action.payload,
    },
    []
  ),
  isLoading: handleActions(
    {
      [fetchSubscriptions]: () => true,
      [fetchSubscriptionsSuccess]: () => false,
      [fetchSubscriptionFailure]: () => false,
    },
    false
  ),
});
