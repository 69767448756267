import React from "react";
import styled from "styled-components";

import DescriptionItem from "./DescriptionItem";

const Item = ({ title, headerColor, description, checked }) => {
  return (
    <Wrapper>
      <Header color={headerColor}>
        <span>Pro account</span>
        <Title>{title}</Title>
      </Header>
      <Body>
        {description.map((data, i) => (
          <DescriptionItem key={i} label={data.label} value={data.value} />
        ))}
      </Body>
      <Overlay visible={!checked} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 180px;
  margin-bottom: 10px;
  border-radius: 5px;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 15px 18px;
  color: #fff;
  font-size: 12px;
  background: ${({ color }) => color};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 15px 8px;
  background: #232938;
  &:nth-last-child(1) {
    color: #fff;
  }
`;

const Title = styled.p`
  font-size: 26px;
  font-weight: 900;
  text-transform: uppercase;
  margin-top: 5px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export default Item;
