import React, { Component } from "react";
import View from "./View";
import { transformValue } from "../../util";

const viewMax = 140;
const viewMin = 0;
const valueMax = 10;
const valueMin = 0;

export default class Volatility extends Component {
  render() {
    let { value, isNull } = this.props;
    value = value > valueMax ? 0 : value;

    return (
      <View
        value={Math.round(
          transformValue(value, valueMin, valueMax, viewMin, viewMax)
        )}
        isNull={isNull}
      />
    );
  }
}
