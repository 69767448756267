import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import params from "../../../../../../config";
import {
  changePreset,
  deletePresetThunk,
  getFiltersAssets,
  getFiltersPresets,
  setFiltersAsset,
  createPresetThunk,
  getPresetError,
} from "../../../../../../modules/filtersSettings";
import { getFiltersConfig } from "../../../../../../modules/initial";
import View from "./View";

class FiltersTab extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    availableFilters: PropTypes.object.isRequired,
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        selected: PropTypes.array.isRequired,
        algorithm: PropTypes.array.isRequired,
        power: PropTypes.array.isRequired,
        expiration: PropTypes.array.isRequired,
      })
    ).isRequired,
  };

  state = {
    currentAssetId: null,
  };

  componentDidMount() {
    const {
      availableFilters: { assets, alg, expirations, periods },
      setFiltersAsset,
    } = this.props;
    const currentAssetId = Object.entries(assets)[0][0];
    this.setState({ currentAssetId });

    Object.entries(assets).forEach(([assetId]) => {
      const currentAsset = this.props.assets.find(({ id }) => assetId === id);
      if (!currentAsset) {
        setFiltersAsset({
          selected: [...assets[assetId]],
          power: [...Array(6).keys()],
          algorithm: Object.entries(alg).map(([key, name]) => key),
          expiration:
            params.appEnvType === "binary" ? [...expirations] : [...periods],
          id: assetId,
        });
      }
    });
  }

  handleAssetChange = ({ value }) => {
    this.setState({ currentAssetId: value });
  };

  handleItemChange = (e) => {
    const { assets, setFiltersAsset } = this.props;
    const currentAsset = assets.find(
      ({ id }) => this.state.currentAssetId === id
    );

    let [key, value] = e.target.name.split("_");
    value = ["power", "expiration"].indexOf(key) > -1 ? parseInt(value) : value;

    let items = [...currentAsset[key]];
    const isset = items.indexOf(value) > -1;

    if (isset) {
      items = items.filter((item) => item !== value);
    } else {
      items.push(value);
    }

    setFiltersAsset({ ...currentAsset, [key]: items });
  };

  handleCreatePreset = (presetName) => {
    if (presetName.length) {
      const { createPresetThunk } = this.props;
      const currentAsset = this.props.assets.find(
        (asset) => asset.id === this.state.currentAssetId
      );
      createPresetThunk({ ...currentAsset, presetName });
    }
  };

  handleDeletePreset = (presetId) => {
    const { deletePresetThunk } = this.props;
    deletePresetThunk(presetId);
  };

  handleChangePreset = (preset) => {
    const { setFiltersAsset } = this.props;
    setFiltersAsset(preset);
  };

  render() {
    const { availableFilters, assets, isActive } = this.props;
    let { presets } = this.props;
    let { currentAssetId } = this.state;

    if (!currentAssetId) {
      return null;
    }

    const currentAsset = assets.find(({ id }) => currentAssetId === id);

    if (!currentAsset) {
      return null;
    }

    presets = presets.filter(({ id }) => id === currentAsset.id);

    return (
      <View
        {...{ availableFilters, isActive, currentAsset }}
        presetError={this.props.presetError}
        presets={presets}
        onAssetChange={this.handleAssetChange}
        onItemChange={this.handleItemChange}
        onCreatePreset={this.handleCreatePreset}
        onDeletePreset={this.handleDeletePreset}
        onChangePreset={this.handleChangePreset}
      />
    );
  }
}

export default connect(
  (state) => ({
    availableFilters: getFiltersConfig(state),
    assets: getFiltersAssets(state),
    presets: getFiltersPresets(state),
    presetError: getPresetError(state),
  }),
  {
    setFiltersAsset,
    createPresetThunk,
    deletePresetThunk,
    changePreset,
  }
)(FiltersTab);
