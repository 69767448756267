import React, { Component } from "react";
import View from "./View";

export default class Summary extends Component {
  calcSummaryViewParams = () => {
    const { bulls, bears, pwr, rsi, cci, trends } = this.props.value;

    const pwrDegree = pwr * 9;
    let pwrImgIndex = 1;
    if (pwrDegree <= -55) pwrImgIndex = 1;
    if (pwrDegree > -55 && pwrDegree < -19) pwrImgIndex = 2;
    if (pwrDegree >= -19 && pwrDegree <= 19) pwrImgIndex = 3;
    if (pwrDegree > 19 && pwrDegree < 55) pwrImgIndex = 4;
    if (pwrDegree >= 55) pwrImgIndex = 5;

    const bullsImgIndex =
      Math.round(bulls * 1.9) >= 1 ? Math.round(bulls * 1.9) : 1;
    const bearsImgIndex =
      Math.round(bears * 1.9) >= 1 ? Math.round(bears * 1.9) : 1;

    const trendImgIndex = {
      0: 'red3', 1: 'red2', 2: 'red1',
      3: 'yellow3', 4: 'yellow2', 5: 'yellow2',
      6: 'green1', 7: 'green2', 8: 'green3'
    }[parseInt(trends)];
    
    return { pwrImgIndex, pwrDegree, bullsImgIndex, bearsImgIndex, rsi, cci, trendImgIndex };
  };

  render() {
    const { symbol, isNull } = this.props;
    return <View {...this.calcSummaryViewParams()} symbol={symbol} isNull={isNull} />;
  }
}
