import React from "react";
import styled from "styled-components";

import bgImage from "./assets/bg.png";

const View = ({ children }) => {
  return (
    <div className="graph">
      {children}
      <Background />
    </div>
  );
};

const Background = styled.div`
  position: absolute;
  inset: 0;
  background: center/cover url("${bgImage}");
`;

export default View;
