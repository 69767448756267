import Pro1HourOfferAlert from "../../../base/alert/Pro1HourOfferAlert";
import { connect } from "react-redux";
import { getAccountInfoRegisteredAt, getGoproLink } from "../../../../modules/initial";
import { getActiveSignalsType, getCurrentTms } from "../../../../modules/client";
import { isClientPRO } from "../../../../modules/mainSettings";
import ForceProAlert from "../../../base/alert/ForceProAlert";
import ForexWeekendAlert from "../../../base/alert/ForexWeekendAlert";
import styled, { css } from "styled-components";

const SignalsContainer = ({ link, currentTime, registerTime, isPro, activeSignalsType, children, activePresetName }) => {
  const isWeekend = [6, 0].indexOf(new Date(currentTime).getDay()) > -1,
    isForexSelected = activeSignalsType?.id.toString().slice(-1) === "0",
    showWeekendAlert = isWeekend && isForexSelected,
    showPro1HourOfferAlert = !isPro && 3600000 > currentTime - registerTime * 1000,
    alertsCounter = Number(!isPro) + Number(showWeekendAlert) + Number(showPro1HourOfferAlert);

  return (
    <>
      <PresetName>{activePresetName}</PresetName>
      {!isPro && <ForceProAlert {...{ link }} />}
      {showWeekendAlert && <ForexWeekendAlert />}
      {showPro1HourOfferAlert && <Pro1HourOfferAlert {...{ link, currentTime, registerTime }} />}

      <SignalsWrapper className="filters_item_wrap" alertsCounter={alertsCounter}>
        {children}
      </SignalsWrapper>
    </>
  );
};

const PresetName = styled.div`
  position: absolute;
  top: 40px;
  left: 50px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 600;
  font-size: 13px;
`;

const SignalsWrapper = styled.div`
  overflow-y: auto;
  padding-bottom: 25px;
  ${(props) =>
    css`
      height: calc(100vh - ${40 + 110 * props.alertsCounter}px);
    `}
`;

export default connect((state) => ({
  link: getGoproLink(state),
  currentTime: getCurrentTms(state),
  registerTime: getAccountInfoRegisteredAt(state),
  isPro: isClientPRO(state),
  activeSignalsType: getActiveSignalsType(state),
}))(SignalsContainer);
