import { Component } from "react";
import { connect } from "react-redux";
import View from "./View";
import MainTab from "./items/MainTab";
import FiltersTab from "./items/FiltersTab";
import SignalViewTab from "./items/SignalViewTab";
import DashboardsTab from "./items/DashboardsTab";
import ViewTab from "./items/ViewTab";
import Brokers from "./items/Brokers";
import VersionInfo from "../VersionInfo";
import SubscriptionTab from "./items/Subscription";
import { isAuth, isClientPRO } from "../../../../modules/mainSettings";
import accountIcon from "../../../base/assets/icon-account.svg";
import brokersIcon from "../../../base/assets/icon-brokers.svg";
import aboutIcon from "../../../base/assets/icon-about.svg";
import dashboardIcon from "../../../base/assets/icon-dashboard.svg";
import filterIcon from "../../../base/assets/icon-filter.svg";
import viewIcon from "../../../base/assets/icon-view.svg";
import signalViewIcon from "../../../base/assets/icon-signal-view.svg";
import params from "../../../../config";
import events from "../../../../packages/eventBus";

const defaultTabs = [
  {
    id: 1,
    name: "Account",
    isActive: false,
    component: MainTab,
    onlyPro: false,
    icon: accountIcon,
    disabled: false,
  },
  {
    id: 2,
    name: "Brokers",
    isActive: false,
    component: Brokers,
    onlyPro: true,
    icon: brokersIcon,
    disabled: false,
  },
  {
    id: 3,
    name: "Filter",
    isActive: false,
    component: FiltersTab,
    onlyPro: true,
    icon: filterIcon,
    disabled: false,
  },
  {
    id: 4,
    name: "Signal View",
    isActive: false,
    component: SignalViewTab,
    onlyPro: true,
    icon: signalViewIcon,
    disabled: params.appEnvType === "binary",
  },
  {
    id: 5,
    name: "Dashboards",
    isActive: false,
    component: DashboardsTab,
    onlyPro: true,
    icon: dashboardIcon,
    disabled: false,
  },
  {
    id: 6,
    name: "View",
    isActive: false,
    component: ViewTab,
    onlyPro: false,
    icon: viewIcon,
    disabled: false,
  },
  {
    id: 7,
    name: "About",
    isActive: false,
    component: VersionInfo,
    onlyPro: false,
    icon: aboutIcon,
    disabled: false,
  },
  {
    id: 8,
    name: "Subscription",
    isActive: false,
    component: SubscriptionTab,
    onlyPro: false,
    icon: aboutIcon,
    disabled: false,
  },
];

class AppSettings extends Component {
  state = {
    tabs: [...defaultTabs],
  };

  componentDidMount() {
    events.openSubscriptionSettings.subscribe(() => {
      this.handleTabChange(8);
    });

    this.handleTabChange(1);
  }

  handleTabChange = (newId) => {
    const { isPro } = this.props;
    const selectedTab = defaultTabs.find(({ id }) => id === newId);

    if (selectedTab.onlyPro && !isPro) return;

    this.setState({
      tabs: [
        ...defaultTabs.filter(({ id }) => id !== newId),
        {
          ...selectedTab,
          isActive: true,
        },
      ],
    });
  };

  render() {
    const { tabs } = this.state;
    const { isPro, isAuth } = this.props;

    const ActiveTab = tabs.find(({ isActive }) => isActive === true)?.component;
    return (
      <View {...this.props} onTabClick={this.handleTabChange} {...{ tabs, isPro, isAuth }}>
        {ActiveTab && <ActiveTab isActive={true} />}
      </View>
    );
  }
}

export default connect((state) => ({
  isPro: isClientPRO(state),
  isAuth: isAuth(state),
}))(AppSettings);
