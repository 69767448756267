import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import View from "./View";
import { getSubscriptions } from "../../../../../../modules/subscriptions";

import { ReactComponent as MicrosoftPaymentImg } from "./assets/microsoft-store.svg";
import { ReactComponent as WebsitePaymentImg } from "./assets/website-payment.svg";
import { openLink } from "../../../../../../utils";

const payments = [
  { id: "store", label: "Microsoft store", image: <MicrosoftPaymentImg /> },
  { id: "website", label: "From the website", image: <WebsitePaymentImg /> },
];

const headerColors = ["#98A4C3", "#FC8B8C", "#81C728", "#988FF2"];

export const convertDays = (days) => {
  if (days >= 365) {
    return `${days / 365} Year`;
  }

  if (days >= 30) {
    return `${days / 30} Month`;
  }

  return `${days} Days`;
};

const Subscription = () => {
  const subscriptions = useSelector(getSubscriptions);
  const dispatch = useDispatch();

  const [checkedSub, setCheckedSub] = useState(subscriptions[0]);
  const [checkedPayment, setCheckedPayment] = useState(payments[0].id);

  if (!subscriptions.length) return null;

  const handleSubChange = (id) => {
    setCheckedSub(subscriptions.find((sub) => sub.id === id));
  };

  const handleBuyClicked = () => {
    if (checkedPayment === "website") {
      // dispatch(openLinkThunk({ url: `defbrowser::https://vfxalert.com/store/order/${checkedSub.product_id}` }));
      openLink({ url: `defbrowser::https://vfxalert.com/store/order/${checkedSub.product_id}` });
    }

    const data = {
      event: "pro_update",
      store_type: checkedPayment,
      ...checkedSub,
    };

    console.log("sent to native:", data);
    if (window.vfx_sent_to_native) {
      window.vfx_sent_to_native(JSON.stringify(data));
    }
  };

  const subs = subscriptions.map((item, i) => ({
    id: item.id,
    title: item.title,
    headerColor: headerColors[i],
    description: [
      {
        label: "Valid",
        value: convertDays(item.valid),
      },
      {
        label: "Alerts pack*",
        value: item.alertsPack,
      },
      {
        label: "Price",
        value: `$${item.price / 100}`,
      },
    ],
  }));

  return (
    <View
      subscriptions={subs}
      payments={payments}
      checkedSubId={checkedSub.id}
      checkedPaymentId={checkedPayment}
      onSubChange={handleSubChange}
      onPaymentChange={setCheckedPayment}
      onBuyClicked={handleBuyClicked}
    />
  );
};

export default Subscription;
