import { getLogs } from "./selectors";
import { clearLogs } from "./actions";
import { getWSConnection } from "../socket";
import packageJSON from "../../../package.json";
import { getAccountInfoUserId } from "../initial";
import { ThunkDispatch } from "redux-thunk";
import { GetState } from "../../store";

const SENDING_LOG_INTERVAL = 1000 * 60;
const isDev = process.env.NODE_ENV === "development";

export const initLoggerThunk = () => (dispatch: ThunkDispatch<any, any, any>) => {
  if (isDev) {
    console.log("Logs was not sent because application running on DEV mode");
  }
  dispatch(sendLogsThunk());
  setInterval(() => {
    dispatch(sendLogsThunk());
  }, SENDING_LOG_INTERVAL);
};

export const sendLogsThunk = () => (dispatch: ThunkDispatch<any, any, any>, getState: GetState) => {
  const state = getState();
  const logs = getLogs(state);
  const vfxUserId = getAccountInfoUserId(state);
  const wsConnection = getWSConnection();

  if (isDev) {
    dispatch(clearLogs());
    return;
  }

  if (!wsConnection || !logs.length) return;

  const client = {
    source: "desktop",
    platform: window.electron?.platform || "native",
    version: packageJSON.version,
    hash: packageJSON.hash,
  };

  const userInfo = {
    vfxUserId,
  };

  console.log("send logs", { logs, client, userInfo });

  wsConnection.emit("app:log", { logs, client, userInfo });
  dispatch(clearLogs());
};
