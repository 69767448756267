import styled from "styled-components";
import iconKey from "../assets/icon-key.svg";
import iconLock from "../assets/icon-input-lock.svg";
import iconMail from "../assets/icon-mail.svg";
import iconBrokerName from "../assets/icon-broker-name.svg";
import iconBrokerUrl from "../assets/icon-broker-url.svg";

const icons = {
  "license-key": iconKey,
  lock: iconLock,
  mail: iconMail,
  "broker-name": iconBrokerName,
  "broker-url": iconBrokerUrl,
};

const Input = ({ label, image, buttonText, disabled, onClick, ...rest }) => {
  return (
    <Container disabled={disabled}>
      <Label>{label}</Label>
      <StyledInputContainer withButton={!!buttonText}>
        <StyledInput disabled={disabled} {...rest} withButton={!!buttonText} />
        {buttonText && <Button onClick={onClick}>{buttonText}</Button>}
        {image && <Img src={icons[image]} />}
      </StyledInputContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 15px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const StyledInputContainer = styled.div`
  border: 2px solid #9ea3ae;
  width: 100%;
  border-radius: 5px;
  height: 35px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;
const StyledInput = styled.input`
  border: none;
  background-color: transparent;
  color: #fff;
  padding: 2px 10px;
  font-size: 15px;
  flex: 1 0 auto;
`;
const Label = styled.label`
  font-size: 15px;
  color: #8f939b;
`;
const Img = styled.img`
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
`;
const Button = styled.button`
  position: relative;
  right: -2px;
  top: -2px;
  height: calc(100% + 4px);
  background-color: #0867e5;
  padding: 0 20px;
  color: white;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  white-space: nowrap;
`;

export default Input;
