import React, { Component } from "react";
import View from "./View";
import PropTypes from "prop-types";

class TermSwitcher extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired
  };

  render() {
    const { onChange, value } = this.props;
    return <View value={value} handleClick={onChange}/>;
  }
}

export default TermSwitcher;