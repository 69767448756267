import React from "react";
import styled from "styled-components";

import Item from "./Item";
import Radio from "../../../../../../base/form/Radio";

const SubItem = ({ id, title, headerColor, description, checked, onSubChange }) => {
  return (
    <Wrapper onClick={() => onSubChange(id)}>
      <Item title={title} headerColor={headerColor} description={description} checked={checked} />
      <Radio style={{ width: 15, height: 15 }} active={checked} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export default SubItem;
