import React from "react";
import styled from "styled-components";

import iconWarning from "../assets/icon-warning.svg";

const ErrorMessage = ({ width, errorText }) => {
  return (
    <ErrorContainer>
      <IconWarning src={iconWarning} />
      <ErrorText>{errorText}</ErrorText>
    </ErrorContainer>
  );
};
const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.width ? props.width : "100%")};
  /* width: 100%; */
`;

const IconWarning = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;

const ErrorText = styled.div`
  color: #ff3d71;
  font-size: 13px;
`;

export default ErrorMessage;
