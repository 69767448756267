import { createAction } from "redux-actions";

// payload {dashboardId: 'index in items array', value: true|false}
export const setEnabled = createAction("DASHBOARD/SET_ENABLED");

// payload {dashboardId: 'index in items array', value: 1|5|15|50}
export const setTerm = createAction("DASHBOARD/SET_TERM");

// payload {dashboardId: 'index in items array', value: ex.'EURUSD'}
export const setSymbol = createAction("DASHBOARD/SET_SYMBOL");

// payload {dashboardId: 'index in items array', value: { summary: true, zigzag: true, piv: true, volatility: true }}
export const setVisibleSettings = createAction("DASHBOARD/SET_VISIBLE_SETTINGS");

// payload {value: ['eurusd', 'btcusd']}
export const initDashboard = createAction("DASHBOARD/INIT");
