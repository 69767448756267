import React from "react";
import styled from "styled-components";
import { ReactComponent as IndicatorIcon } from "../assets/indicator-icon.svg";
import { ReactComponent as TimerIcon } from "../assets/timer-icon.svg";
import { ReactComponent as GraphIcon } from "../assets/graph-icon.svg";
import Variant1 from "./Variant1";
import Variant2 from "./Variant2";
import Variant3 from "./Variant3";
import Variant4 from "./Variant4";

const VariantsContainer = (props) => {
  const { variant, alg, period, updatedServerSync } = props;
  return (
    <Container>
      <PriceContainer>
        {variant === 1 && <Variant1 {...props}/>}
        {variant === 2 && <Variant2 {...props}/>}
        {variant === 3 && <Variant3 {...props}/>}
        {variant === 4 && <Variant4 {...props}/>}
      </PriceContainer>
      <MetaContainer>
        <MetaItem>
          <TimerIcon width={"13px"} height={"13px"}/>
          <MetaLabel>{updatedServerSync}</MetaLabel>
        </MetaItem>
        <MetaItem>
          <GraphIcon width={"13px"} height={"13px"} fill="#91949b"/>
          <MetaLabel>{period} min</MetaLabel>
        </MetaItem>
        <MetaItem>
          <IndicatorIcon width={"13px"} height={"13px"}/>
          <MetaLabel>{alg}</MetaLabel>
        </MetaItem>
      </MetaContainer>
    </Container>
  );
};

export default VariantsContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const PriceContainer = styled.div``;
const MetaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;
const MetaItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 15px;
`;
const MetaLabel = styled.div`
  margin-left: 3px;
  color: #91949b;
  font-weight: 500;
  font-size: 9px;
`;
