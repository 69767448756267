import styled from "styled-components";

const BaseAlert = styled.div`
  width: calc(100% - 1px);
  height: 105px;
  border-radius: 3px;
  position: relative;
`;

export default BaseAlert;
