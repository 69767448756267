import React, { useEffect, useState } from "react";

import View from "./View";

import img1 from "./assets/1.png";
import img2 from "./assets/2.png";
import img3 from "./assets/3.png";
import img4 from "./assets/4.png";
import img5 from "./assets/5.png";
import { getLinks } from "../../../../modules/initial";
import { useSelector } from "react-redux";
import { openLink } from "../../../../utils";
import { isClientPRO } from "../../../../modules/mainSettings";
import useLogger from "../../../../hooks/useLogger";

const Tutorial = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const links = useSelector(getLinks);
  const isPro = useSelector(isClientPRO);
  const logger = useLogger();

  useEffect(() => {
    if (props.isActive) {
      logger.info("Tutorial: has opened");
    } else {
      logger.info(`Tutorial: user closed the tutorial on ${activeStep + 1} step with title text: "${steps[activeStep].title}"`);
    }
  }, [props.isActive]);

  useEffect(() => {
    logger.info(`Tutorial: user has opened ${activeStep + 1} step with title text: ${steps[activeStep].title}`);
  }, [activeStep]);

  const handleUpgradeLicenseClick = () => {
    logger.info("Tutorial: user clicked on upgrade license button");
    openLink({ url: links.gopro });
  };

  let steps = [
    {
      title: "Connect with a broker",
      description: "",
      buttonText: "",
      onButtonClick: null,
      image: img1,
      visible: true,
    },
    {
      title: "Choose a signal",
      description:
        "Signal power shows statistics of signal success, heatmaps shows the probability of signal success depending on the indicator.",
      buttonText: "",
      onButtonClick: null,
      image: img2,
      visible: true,
    },
    {
      title: "Power and heatmaps are not available for free account.",
      description: "",
      buttonText: "Upgrade to PRO",
      onButtonClick: handleUpgradeLicenseClick,
      image: img3,
      visible: !isPro,
    },
    {
      title: "Analyze the market at the moment using trading indicators on dashboards.",
      description: "",
      buttonText: "",
      onButtonClick: null,
      image: img4,
      visible: true,
    },
    {
      title: "Open a position in one click",
      description: "",
      buttonText: "",
      onButtonClick: null,
      image: img5,
      visible: true,
    },
  ];

  steps = steps.filter(({ visible }) => visible);

  const data = {
    ...props,
    steps,
    activeIndex: activeStep,
    onChange: setActiveStep,
  };

  return <View {...data} />;
};

export default Tutorial;
