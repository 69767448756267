import React, { Component } from "react";
import { connect } from "react-redux";

import brokers from "../../modules/broker";
import {
  getAvailableBrokerIntegrationList,
  openActiveBrokerTabThunk,
  openBrokerTabThunk,
  setActiveBroker,
  setShowConnectBrokersThunk,
} from "../../modules/client";
import { getAccountInfoEmail, getAccountInfoUserId, getBrokersList, isDebug, setBrokerLink } from "../../modules/initial";
import NotificationManager from "../../packages/notifications/ClientNotification";
import { openLink } from "../../utils";

const availableBrokerRegistrations = [
  { code: "pocketoption", weight: 3 },
  { code: "olymptrade", weight: 2 },
  { code: "iqoption", weight: 1 },
];

class StartTabContent extends Component {
  state = { isInitialized: false, addedListeners: false };

  iframe = React.createRef();

  componentDidUpdate() {
    const { userEmail } = this.props;
    if (this.webview && userEmail) {
      try {
        this.webview.executeJavaScript(`enterUserCreds("${userEmail}", "")`);
      } catch (e) {}
    }
  }

  render() {
    return null;
  }
}

export default connect((state) => ({
  data: state.initial.data,
  userEmail: getAccountInfoEmail(state),
  availableBrokerList: getBrokersList(state),
  vfxUserId: getAccountInfoUserId(state),
  availableBrokerIntegrationList: getAvailableBrokerIntegrationList(state),
  isDebug: isDebug(state),
}))(StartTabContent);
