import styled, { css } from "styled-components";
import Input from "../../../../../base/form/Input";
import Button from "../../../../../base/form/Button";
import React from "react";
import iconWarning from "../../../../../base/assets/icon-warning.svg";
import iconRemove from "../../../../../base/assets/icon-trash.svg";

const View = ({
  showBrokersItemForm,
  removeCustomBrokersItem,
  handleBrokersItemForm,
  handleBrokersItemAdd,
  onFormFieldChange,
  brokersList,
  newBrokerTitle,
  newBrokerUrl,
  brokerUrlError,
}) => (
  <Container>
    <BrokerForm>
      <StyledInput
        label={"Broker name"}
        image={"broker-name"}
        value={newBrokerTitle}
        onChange={onFormFieldChange}
        name="newBrokerTitle"
      />
      <StyledInput
        label={"Broker URL"}
        image={"broker-url"}
        value={newBrokerUrl}
        onChange={onFormFieldChange}
        name="newBrokerUrl"
      />
      <StyledButton onClick={handleBrokersItemAdd}>+ Add broker</StyledButton>
      <ErrorContainer>
        {brokerUrlError && (<>
          <IconWarning src={iconWarning}/>
          <ErrorText>You entered an invalid broker data</ErrorText>
        </>)}
      </ErrorContainer>
    </BrokerForm>
    <BrokersList>
      <BrokersListHeader>
        <BrokersListTextItem>#</BrokersListTextItem>
        <BrokersListTextItem>Broker Name</BrokersListTextItem>
        <BrokersListTextItem>Broker URL</BrokersListTextItem>
        <BrokersListTextItem />
      </BrokersListHeader>
      {brokersList.length > 0 &&
        brokersList.map(({ id, title, url }, index) => (
          <BrokersListRow key={id}>
            <BrokersListTextItem>{index + 1}</BrokersListTextItem>
            <BrokersListTextItem>{title}</BrokersListTextItem>
            <BrokersListTextItem><CuttedUrl>{url}</CuttedUrl></BrokersListTextItem>
            <BrokersListTextItem>
              <RemoveItemButton onClick={() => {
                removeCustomBrokersItem(id);
              }}/>
            </BrokersListTextItem>
          </BrokersListRow>
      ))}
    </BrokersList>
  </Container>
)
const Container = styled.div`
  padding: 20px;
  width: 740px;
`;
const BrokerForm = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 35px;
`;
const StyledInput = styled(Input)`
  width: 270px;
`
const StyledButton = styled(Button)`
  width: 130px;
  padding: 10px 0;
`;
const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
`
const IconWarning = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`
const ErrorText = styled.div`
  color: #FF3D71;
  font-size: 13px;
`
const CuttedUrl = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
const BrokersList = styled.div`
  font-size: 14px;
`;
const BrokersListTextItem = styled.div``;
const BrokersListRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px rgba(255,255,255,0.7);
  color: rgba(255,255,255,0.7);
  padding: 12px 0;
  font-weight: 300;
  ${BrokersListTextItem}:first-child {
    width: 40px;
    text-align: center;
    margin-right: 20px;
  }
  ${BrokersListTextItem}:last-child {
    width: 30px;
  }
  ${BrokersListTextItem}:nth-child(2), ${BrokersListTextItem}:nth-child(3) {
    width: calc(50% - 45px);
  }
`
const BrokersListHeader = styled(BrokersListRow)`
  padding: 10px 0;
  border-bottom: solid 1px #FFFFFF;
  color: #FFFFFF;
  font-weight: 500;
`
const RemoveItemButton = styled.div`
  padding: 2px;
  width: 24px;
  height: 24px;
  background-color: #3366FF;
  cursor: pointer;
  mask-image: url(${iconRemove});
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;

export default View