import React, { useState } from "react";
import styled from "styled-components";
import { TelegramMessagesProps } from "./index";
import Loader from "../../../base/Loader";

interface TelegramMessagesViewProps extends TelegramMessagesProps {
  url: string;
}

const TelegramMessagesView: React.FC<TelegramMessagesViewProps> = ({ isActive, url }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Wrapper isActive={isActive}>
      <Header>@vfxAlert_binarysignals</Header>
      <WebViewWrapper>
        <LoaderWrapper active={isLoading}>
          <Loader />
        </LoaderWrapper>
        <webview
          style={{ height: "100%", width: "100%" }}
          src={url}
          ref={(webview) => {
            webview?.addEventListener("dom-ready", () => {
              setIsLoading(false);
            });
          }}
        />
      </WebViewWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ isActive: boolean }>`
  width: 350px;
  height: 255px;
  margin-right: 9px;
  margin-left: -9px;
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  position: absolute;
  left: 45px;
  bottom: 0;
  background-color: #2f3545;
  z-index: 5;
`;

const Header = styled.div`
  height: 35px;
  padding-left: 15px;
  width: 100%;
  background-color: #3b4356;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: white;
`;

const LoaderWrapper = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${({ active }) => (active ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const WebViewWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 35px);
`;

export default TelegramMessagesView;
