import { receiveSignals } from "../signals";
import { receiveIndicators } from "../indicators";
import { updateTms } from "../client";
import { getAccountInfoEmail, getAccountInfoUserId } from "../initial";
import { WSConnection } from "./wsConnection";
import packageJson from "../../../package.json";
import pako from "pako";
import SystemNotificationManager from "../../packages/notifications/SystemNotification";

let wsConnection = null;

export const connectSocket =
  ({ host, channel }) =>
  (dispatch, getState) => {
    if (wsConnection) return;

    wsConnection = new WSConnection({
      vfxUserId: getAccountInfoUserId(getState()),
      email: getAccountInfoEmail(getState()),
      // lkey: getAccountInfoLkey(getState()),
      // isDebug: isDebug(getState()),
      version: packageJson.version,
      build: packageJson.hash,
      platform: window.platform ?? "browser",
      host,
      channel,
    });

    wsConnection
      .subscribe("SIGNALS_INDICATORS_ROOM_GZIP", (message) => {
        let json = {};
        try {
          json = JSON.parse(message);
        } catch (e) {
          console.error("Invalid json SIGNALS_INDICATORS_ROOM_GZIP data", message);
          return;
        }

        const { tms, data } = json;
        if (!tms || !data) {
          console.error("Invalid format SIGNALS_INDICATORS_ROOM_GZIP data", message);
          return;
        }

        const dataParsed = unpackGzip(data);

        dispatch(receiveSignals(dataParsed.signals));
        dispatch(receiveIndicators(dataParsed.indicators));
        dispatch(updateTms(tms));
      })

      .subscribe("SYSTEM_MESSAGE", (message) => {
        try {
          const { type, title, body } = JSON.parse(message);

          if (!type || !title || !body) {
            console.error("[SYSTEM NOTIFICATION] Invalid json SYSTEM_MESSAGE data", message);
            return;
          }

          if (!["success", "info", "warning", "error"].includes(type)) {
            console.error("[SYSTEM NOTIFICATION] Unknown notification type:", type);
            return;
          }

          SystemNotificationManager[type](title, body);
        } catch (e) {}
      });
  };

export const getWSConnection = () => wsConnection;

const unpackGzip = (data) => {
  // Decode base64 (convert ascii to binary)
  const strData = atob(data);
  // Convert binary string to character-number array
  const charData = strData.split("").map(function (x) {
    return x.charCodeAt(0);
  });
  // Turn number array into byte-array
  const binData = new Uint8Array(charData);
  // Pako magic
  const packageData = pako.inflate(binData);
  // Convert gunzipped byteArray back to ascii string:
  const json = String.fromCharCode.apply(null, new Uint16Array(packageData));
  // Output to console
  return JSON.parse(json);
};
