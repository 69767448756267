import { addLogAction } from "./actions";
import store from "../../store";
import { Dispatch } from "redux";
import { LogType } from "./types";

export { default } from "./reducer";
export * from "./actions";
export * from "./thunks";
export * from "./selectors";

window.mainLogs?.registerHandler((data: LogType) => {
  const logger = getLogger(store.dispatch);
  const { level, message, context } = data;
  logger[level](`MainProcess: ${message}`, context);
});

export const getLogger = (dispatch: Dispatch) => ({
  success: (message: string, context = {}) => {
    dispatch(addLogAction({ level: "success", message: `[Desktop] ${message}`, context: { ...context, tms: Date.now() } }));
  },
  info: (message: string, context = {}) => {
    dispatch(addLogAction({ level: "info", message: `[Desktop] ${message}`, context: { ...context, tms: Date.now() } }));
  },
  warning: (message: string, context = {}) => {
    dispatch(addLogAction({ level: "warning", message: `[Desktop] ${message}`, context: { ...context, tms: Date.now() } }));
  },
  error: (message: string, context = {}) => {
    dispatch(addLogAction({ level: "error", message: `[Desktop] ${message}`, context: { ...context, tms: Date.now() } }));
  },
});
