import React from "react";

import View from "./View";
import { useSelector } from "react-redux";
import { getLinks } from "../../../../modules/initial";

export interface TelegramMessagesProps {
  isActive: boolean;
}

const TelegramMessages: React.FC<TelegramMessagesProps> = (props) => {
  const links = useSelector(getLinks);

  const data = {
    ...props,
    url: links.tg_channel,
  };

  return <View {...data} />;
};

export default TelegramMessages;
